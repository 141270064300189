<template>
    <b-nav align="right">
        <b-button variant="link" class="volver" :href="core_uri + '/inicio.mod.php'"><b-icon-arrow-left-square/> Ir al inicio</b-button>
        <b-button variant="white" class="pt-0">
            <b-dropdown id="dropdown-1" variant="white" right no-caret>
                <template #button-content>
                <b-icon icon="person-fill"></b-icon>
                </template>
                <b-dropdown-item :href="core_uri+logout">
                Cerrar Sesión
                <b-iconstack>
                    <b-icon stacked icon="door-open-fill" scale="0.75"></b-icon>
                    <b-icon stacked icon="arrow-right-short" shift-h="6" scale="0.75"></b-icon>
                </b-iconstack>
                </b-dropdown-item>
            </b-dropdown>
        </b-button>
    </b-nav>
</template>
<script>
export default {
    name: 'MenuUsuario',
    props: {
        core_uri:String,
    },
    data() {
        return {
           logout: '/login.mod.php?q=YWNjaW9uPWNsb3NlU2Vzc2lvbg%3D%3D'
        }
    }
}
</script>