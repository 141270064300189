<template>
<b-overlay
    class="wrapper color-over"
    style="position:initial!important"
    :class="show || tokenDefeated || otherErr || (manual_supplies.length <= 5)? 'over': ''"
    :show="show"
    opacity="1"> 
    <b-container :fluid="isMobile ? false : true">
        <!-- TABLA -->
        <b-table :striped="true" table-variant="light" 
            primary-key="nro_remito" :items="manual_supplies" :fields="fields"
            :responsive="isMobile ? true : false"
            :stacked="isMobile"
            :busy="busy"
            caption-top
            :style="isMobile ? 'font-size:14px': ''"
            v-if="!tokenDefeated && !otherErr"
            :show-empty="true"
            empty-text="No se encontraron remitos."
            sort-by="fecha_hora"
            sort-desc
        >
            <template #table-caption>                
                <div class="d-flex justify-content-between">
                    <p v-if="!busy && hasManualSupplies" style="font-size:12px" class="m-0"> 
                        <b>
                        Mostrando todos los resultados.
                        </b>
                    </p>
                    <p v-else></p>
                    <div> 
                        <b-button variant="success" size="sm"  class="mr-3" 
                            v-if="tienePermisoCreate"
                            @click="crear"
                            >
                            <b-icon-plus></b-icon-plus>
                        </b-button>
                    </div>
                </div>
            </template>        
            
            <template #table-busy>
                <b-skeleton-table
                :rows="7"
                :columns="3"
                :table-props="{ borderless: true, tableVariant: 'light', striped: true }"></b-skeleton-table>
            </template>
            <template #cell(fecha_hora)="data">
                {{ new Date(data.value).toLocaleString('pt' ,{dateStyle: 'short',timeStyle: 'short'}) }}
            </template>
            <template #cell(cantidad_remito)="data">
                {{ data.value.toLocaleString('de', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }} lts.
            </template>

            <template #cell(cuit)="data">
                {{ parseCUIT(data.value) }}
            </template>
            <template #cell(operador)="data">
                <p v-if="data.value.nombre_completo !== null">
                    {{ data.value.nombre_completo }}
                </p>
                <p v-else>
                {{ findOperador(data.value)}}
                </p>
            </template>
            <!-- <template #cell(image_url)="data">
                <img v-if="data.value" :src="data.value" alt="" width="200px">
                <p v-else>Sin imagen</p>
            </template> -->
            <template #row-details="row">
                <b-table :stacked="isMobile ? true : false" :items="row.item.items" :fields="detailRowFields"
                bordered small table-variant="info">

                </b-table>
            </template>

            <!-- acciones -->
            <template #cell(actions)="row">
                <b-row cols="12" :class="isMobile ? 'justify-content-center' : 'justify-content-end'" style="margin-right:0.5em!important">
                    <b-button v-if="row.item.items.length != 0" size="sm" @click="row.toggleDetails" class="mr-1">
                        <b-icon :icon="row.detailsShowing ? 'chevron-up' : 'chevron-down'">
                        </b-icon>
                    </b-button>
                    <b-button v-if="tienePermisoUpdate" size="sm" @click="editar(row.item)" class="mr-1" align-self="end">
                        <b-icon-pencil-fill></b-icon-pencil-fill>
                    </b-button>
                    <b-button v-if="!tienePermisoUpdate" size="sm" @click="editar(row.item)" class="mr-1" align-self="end">
                        <b-icon-eye-fill></b-icon-eye-fill>
                    </b-button>
                    <b-button v-if="tienePermisoDelete" size="sm" variant="danger" @click="deleteRemito(row.item)">
                        <b-icon-trash-fill></b-icon-trash-fill>
                    </b-button>
                </b-row>
            </template>

        </b-table>

        <div style="text-align:center;margin-top:15%;" v-else>
            <div style="text-align:center;margin-top:15%;" v-show="tokenDefeated">
                <p class="h1 mb-2"><b-icon icon="x-circle-fill" variant="danger"></b-icon> Sesión Vencida</p>
                <p style="font-size:20px">Para ver los datos debe reiniciar sesión en la plataforma</p>
            </div>
            <div style="text-align:center;margin-top:15%;" v-show="otherErr">
                <b-icon style="width:90px; height: 90px;" icon="exclamation-triangle-fill" variant="danger"></b-icon> <p class="h4 mb-2">{{' '+this.textErr+' '}}</p>
            </div>
        </div>

        <b-modal id="modal-crear-remito"
            :title="tituloModal"
            size="xl"
            centered
            @hide="hide"
            @close="close"
            body-class="p-2 modal-height">
            <b-form>
                <b-tabs pills :class="isMobile ? 'm-0 p-0':''" :small="isMobile ? true : false" :vertical="isMenor ? false : true" v-model="tabIndex" active-nav-item-class="font-weight-bold text-uppercase text-black">

                    <b-tab :title="isMobile? 'Datos' : 'Datos del remito'" class="py-1 px-3">
                        <b-card-text>
                            <b-form-group
                                label-cols-sm="4"
                                label-cols-lg="3"
                                id="nro_remito"
                                content-cols-sm="10"
                                description="Inserte el N° Remito"
                                >
                                <label
                                style="color:rgba(0, 0, 0, 0.8);font-size:14px"
                                for="nro_remito">N° Remito*</label>
                                <b-form-input
                                    :state="nroRemitoState"
                                    v-model="form.nro_remito"
                                    :disabled="isCreating ? false : true"
                                    id="nro_remito">
                                </b-form-input>
                            </b-form-group>
                            <b-row>
                                <b-col :cols="isMobile? '6' : '5'">   
                                    <b-form-group
                                        id="fecha"
                                        description="Inserte la fecha del remito"
                                        >                                
                                        <label 
                                        style="color:rgba(0, 0, 0, 0.8);font-size:14px"
                                        for="fecha"
                                        >Fecha*</label>
                                        <b-form-input
                                            :state="fechaState"
                                            v-model="form.fecha"
                                            :disabled="isCreating ? false : true"
                                            type="date" id="fecha"></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col :cols="isMobile? '6' : '5'">
                                    <b-form-group
                                        id="hora" 
                                        description="Inserte la hora del remito"
                                        >
                                        <label 
                                        style="color:rgba(0, 0, 0, 0.8);font-size:14px"
                                        for="hora">Hora*</label>
                                        <b-form-input
                                            v-model="form.hora"
                                            type="time" id="hora"></b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                                <p class="m-0" style="text-align:left;font-size:16px;color:rgba(0, 0, 0, 0.8);">Proveedor*</p>
                                <p style="text-align:left;font-size:14px;">Seleccione un provedor de la lista o complete los datos de uno nuevo</p>
                                <b-form-group
                                    label-cols-sm="4"
                                    label-cols-lg="3"
                                    id="select_proveedor"
                                    content-cols-sm="10">
                                    <label for="select_proveedor"
                                    style="color:rgba(0, 0, 0, 0.8);font-size:14px"
                                    >Listado de proveedores</label>
                                    <v-select 
                                    @input="agregarValorInput"
                                    :disabled= disabledSelect
                                    :searchable="false"
                                    item-value="proveedor"
                                    :placeholder="defaultSelect"
                                    label = "proveedor"
                                    :options="options_proveedores">
                                    </v-select>
                                </b-form-group>
                                <b-row :align-h="isMobile ? 'around' : null" >
                                    <b-col :cols="isMobile? '6' : '5'">
                                        <b-form-group
                                            id="proveedor"
                                            :description="isMobile? '' : 'Inserte la descripción del Proveedor del combustible'"
                                            >
                                            <label 
                                            style="color:rgba(0, 0, 0, 0.8);font-size:14px"
                                            for="proveedor">{{isMobile? 'Proveedor': 'Nombre del Proveedor'}}</label>
                                            <b-form-input
                                                :state="proveedorState"
                                                v-model="form.proveedor"
                                                id="proveedor">
                                            </b-form-input>
                                        </b-form-group>
                                    </b-col>
                                    <b-col :cols="isMobile? '6' : '5'">
                                        <b-form-group
                                            id="cuit"
                                            :description="isMobile? '' : 'Inserte el CUIT (sin guiones)'"
                                            >
                                            <label 
                                            style="color:rgba(0, 0, 0, 0.8);font-size:14px"
                                            for="cuit">CUIT</label>
                                            <b-form-input
                                                :state="cuitState"
                                                v-model="form.cuit"
                                                id="cuit">
                                            </b-form-input>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                        </b-card-text>
                    </b-tab>
                    <b-tab :class="isMobile? 'py-1 px-3 pt-3' : ''" :title="isMobile? 'Adicionales': 'Datos adicionales'" :disabled="activateAditionalTab">
                        <p style="text-align:left;font-size:16px;color:rgba(0, 0, 0, 0.8);">Firmante</p>
                        <p style="text-align:left;font-size:14px;">Seleccione un firmante o complete los datos de un firmante externo</p>
                        <b-form-group
                            label-cols-sm="4"
                            label-cols-lg="3"
                            style="text-align:left;"
                            id="select_firmante"
                            :content-cols="isMobile? '12' : '10'"
                            label-for="select_firmante">
                                <label for="select_firmante"
                                style="color:rgba(0, 0, 0, 0.8);font-size:14px"
                                >Listado de firmantes</label>
                                <v-select 
                                style="background-color:white;"
                                @input="agregarValorInputOperador"
                                :disabled= disabledSelect
                                :searchable="isMobile? false: true"
                                :placeholder= defaultSelectOperador
                                label = "nombre_completo"
                                :options="options_operadores">
                                </v-select>
                        </b-form-group>
                        <b-form-group
                            id="firmante"
                            label-cols-sm="4"
                            label-cols-lg="3"
                            :content-cols="isMobile? '12' : '10'"
                            label="Firmante"
                            label-for="firmante"
                            >
                            <!-- el v-model podria ser otro y despues guardarlo en form.operador.nombre_completo
                            por que se recarga la tabla si cambia el nombre_completo -->
                            <b-form-input
                                v-model="nombre_operador"
                                id="firmante">
                            </b-form-input>
                        </b-form-group>
                    </b-tab>
                    <b-tab :class="isMobile? 'py-1 px-3' : ''" title="Items" :disabled="activateAditionalTab">
                        <template v-if="tabItems == 'tabla'">
                            <b-table :items="items" sticky-header=450px :striped="true" style="color:rgba(0, 0, 0, 0.8);" :fields="fields_items" caption-top >
                                <template #table-caption> 
                                    <b-row class="mr-0">
                                        <b-col cols=9 style="justify-content: left; color:black;">
                                            <h3 :style="isMobile? 'font-size:14px':''">
                                                Agregar items de abastecimiento.
                                            </h3>
                                            <h4 v-if="!isMobile">
                                                Puedes vincular los abastecimientos para detectar existencia de diferencias.  
                                            </h4>
                                        </b-col> 
                                        <b-col class="text-right pl-0">
                                            <b-button
                                                variant="agregar"
                                                class="agregar-item"
                                                :size="isMobile?'sm':''"
                                                role="tab"
                                                @click="agregarItemMenu('agregar-item')"
                                                >
                                                <b-icon-plus v-if="isMenor"></b-icon-plus>
                                                <p v-if="!isMenor" class="m-0 p-0"><b-icon-plus></b-icon-plus>Agregar item</p>
                                            </b-button>
                                        </b-col>
                                    </b-row>      
                                </template> 
                            
                                <!-- acciones -->
                                <template #cell(actions)="index">
                                    <b-row cols="12" align-h="end" :class="isMobile ? 'justify-content-center' : ''" style="margin-right:0.5em!important">
                                        <b-button variant="link"
                                        :style="vinculado(index) ? 'color:green' : ''"
                                        :size="isMobile?'sm':''"
                                        @click="openBindTab(index)">
                                            <b-skeleton height="50%" type="button" v-if="loadingSupplies"></b-skeleton>
                                            <div v-else>
                                                {{vinculado(index) ? 'Vinculado' : 'Vincular'}}
                                                <b-icon-link></b-icon-link>
                                            </div>
                                        </b-button>
                                        <b-button @click="editItem(index)" size="sm" variant="volver">
                                            <b-icon-pencil-fill>
                                            </b-icon-pencil-fill>
                                        </b-button>
                                        <b-button @click="deleteItem(index)" size="sm" variant="volver">
                                            <b-icon-plus-circle rotate="45">
                                            </b-icon-plus-circle>
                                        </b-button>

                                    </b-row>
                                </template>
                            </b-table>

                        </template>
                        <template v-if="tabItems == 'agregar-item'">
                            <b-nav>
                                <p>  
                                <b-button :size="isMobile ? 'sm' : ''" @click="agregarItemMenu('tabla')" variant="volver">
                                    <b-icon-arrow-left>
                                    </b-icon-arrow-left>
                                    Items
                                    <b-icon-chevron-right></b-icon-chevron-right> Nuevo item
                                </b-button> 
                                </p>
                            </b-nav>
                            <b-form @submit="agregarItem">
                                <b-input-group class="justify-content-md-center">
                                    <b-col sm="8">
                                        <label for="equipos" style="font-weight:bold">Equipo: (*)</label>
                                    </b-col>
                                    <b-col sm="8">
                                        <v-select      
                                            v-model="formItem.id_equipo"
                                            label="equipo"
                                            :searchable="false"
                                            :options="options_equipos"
                                            @input="updateProductOptions">
                                        </v-select>
                                        <p v-if="errorProducto" style="color:red">
                                            Debe seleccionar un equipo
                                        </p>
                                    </b-col>
                                </b-input-group>

                                <b-input-group class="justify-content-md-center">
                                    <b-col sm="8">
                                        <label for="productos" style="font-weight:bold">Producto: (*)</label>
                                    </b-col>
                                    <b-col sm="8">
                                        <v-select      
                                            v-model="formItem.producto"
                                            label="nombre_producto"
                                            :options="options_productos"
                                            @input="updateErrorProducto"
                                            :disabled="disableProductos">
                                        </v-select>
                                        <p v-if="errorProducto" style="color:red">
                                            Debe seleccionar un producto
                                        </p>
                                    </b-col>
                                </b-input-group>

                                <b-input-group class="justify-content-md-center">
                                    <b-col sm="8">
                                        <label for="cantidad" style="font-weight:bold">Cantidad (en lts): (*)</label>
                                    </b-col>
                                    <b-col sm="8">
                                        <b-form-input
                                            @input="updateErrorCantidad"
                                            type="number"
                                            :state="isValidCant"
                                            step="10"
                                            v-model="formItem.cantidad"
                                            id="cantidad">
                                        </b-form-input>
                                        <p v-if="errorCantidad" style="color:red">
                                            Debe agregar la cantidad
                                        </p>
                                    </b-col>
                                </b-input-group>

                                <b-input-group class="justify-content-md-center">
                                    <b-col sm="8">
                                        <label for="temperatura">Temperatura (en C°):</label>
                                    </b-col>
                                    <b-col sm="8">
                                        <b-form-input
                                            type="number"
                                            :state="isValidTemp"
                                            step="0.01"
                                            v-model="formItem.temp"
                                            id="temperatura">
                                        </b-form-input>
                                    </b-col>
                                </b-input-group>

                                <b-input-group class="justify-content-md-center">
                                    <b-col sm="8">
                                        <label for="producto_densidad">Densidad a 15°:</label>
                                    </b-col>
                                    <b-col sm="8">
                                        <b-form-input
                                            type="number"
                                            :state="isValidDens"
                                            step="0.01"
                                            v-model="formItem.producto_densidad"
                                            id="producto_densidad">
                                        </b-form-input>
                                    </b-col>
                                </b-input-group>
                            </b-form>
                            <b-row class="justify-content-md-center" v-if="alerta_warning">
                                <b-col class="mt-4" sm="8">
                                    <b-alert class="border-left border-warning" show variant="warning">
                                        <p class="mb-0" style="color:black;font-size: 13px;">
                                            <b-icon-exclamation-triangle style="color: #F2994A;">
                                            </b-icon-exclamation-triangle> Si usted no ingresa un valor de temperatura, pueden surgir errores al momento de conciliar los datos.
                                        </p>
                                    </b-alert>
                                </b-col>
                            </b-row>
                        </template>
                        <template v-if="tabItems == 'vincular'" :disabled="activateBindTab ? false : true">
                            <b-row>
                                <b-col :cols="isMobile? 12 : ''" :class="isMobile? 'pr-0':''">
                                    <p class="p-0 txt">  
                                        <b-button 
                                            :size="isMobile ? 'sm' : ''"
                                            @click="agregarItemMenu('tabla')" 
                                            variant="volver"
                                            :class="isMobile? 'm-0 p-0': ''"
                                            >
                                            <b-icon-arrow-left>
                                            </b-icon-arrow-left>
                                            Items 
                                        <b><b-icon-chevron-right></b-icon-chevron-right> Vinculación</b> 
                                        </b-button> 
                                    </p>
                                </b-col>
                                <b-col :col="isMobile ? 12 : ''" :class="isMobile? 'text-right pl-0':'text-right'">
                                    <p><b>Item: {{currentItem.producto_name}}</b></p>
                                </b-col>
                            </b-row>
                            <b-card-text>
                                <b-col cols="12" :class="isMobile ? 'p-0 mb-1': ''">
                                    <b-row :class="isMobile? 'pt-1' : 'p-1 pt-0'">
                                        <b-col :cols="isMobile? '12' : '10'">
                                            <p class="descripcion-vincular">
                                            Puedes vincular los abastecimientos para detectar existencia de diferencias.  
                                            </p>
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col cols="12">
                                    <!-- Item =>{{ currentItem}} -->
                                    <!-- {{this.selected.map(e => { return e._id, e.id_manual_supply, e.id_manual_supply_item})}} -->
                                    <b-table 
                                        striped :items="possible_supplies" 
                                        :fields="fields_supplies"
                                        @row-clicked="rowClicked" sticky-header="240px"
                                        responsive :tbody-tr-class="tbodyRowClass" :show-empty="true"
                                        :sort-desc="true" :empty-text="msgBindedSupplies">

                                        <template #table-caption>                
                                            <p v-if="msgBindedSupplies != ''">{{msgBindedSupplies}}</p>
                                        </template>        
                                        <template #cell(fecha_hora_start)="data">
                                            {{ new Date(data.value).toLocaleString('es-ES') }}
                                        </template>
                                        <template #cell(cantidad_comp)="data">
                                            {{ data.value.toLocaleString('de', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }} lts.
                                        </template>
                                        <template v-slot:cell(selected)="{ item, field: { key } }" >
                                            <b-form-checkbox :disabled="true" v-model="item[key]"></b-form-checkbox>
                                        </template>
                                    </b-table>
                                </b-col>

                                <b-col cols="12">
                                    <b-col align-self="start">
                                        Seleccionados: <span class="font-weight-bold">{{countSuppliesSelected}}</span>
                                    </b-col> 
                                    <b-col align-self="end" class="text-right">
                                        Cantidad @ 15°C: <span class="font-weight-bold">{{sumSuppliesSelected.toLocaleString('de', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }} lts. </span>
                                    </b-col>                                
                                </b-col>
                            </b-card-text>
                        </template>
                    </b-tab>
                    <b-tab :class="isMobile? 'py-1 px-3 pt-3' : ''" title="Confirmación" ref="resumen" :disabled="activateFinishTab ? false : true">
                            <b-row>
                                <b-col>
                                    <b-row>
                                        <b-col>
                                            <p class="m-0">N° Remito: <b>{{form.nro_remito}}</b></p>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col>
                                            <p>Proveedor: <b>{{form.proveedor}}</b></p>
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col class="text-right">
                                    <b-row>
                                        <b-col>
                                            <p class="m-0">Fecha: <b>{{form.fecha}}</b></p>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col>
                                            <p>Hora: <b>{{form.hora}}</b></p>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                            <b-card-group deck>
                                <b-card class="cards-confirmation">
                                    <b-card-title class="card-title">Cantidad total de items: </b-card-title>
                                    <h3 v-if="getCantidadDeItems() != false">
                                        {{getCantidadDeItems()}} lts. sin compensar
                                    </h3>
                                    <h3 v-if="getCantidadCompDeItems() != false">
                                        {{getCantidadCompDeItems()}} lts. A 15ºC
                                    </h3>
                                    <h3 v-if="getCantidadDeItems() == false && getCantidadCompDeItems() == false">
                                        No se cargo cantidad en todos los items.
                                    </h3>
                                </b-card>
                                <b-card class="cards-confirmation">
                                    <b-card-title class="card-title" >Abastecimientos vinculados: </b-card-title>
                                    <h3  v-if="getCantidadDeAbastecimientos() != false && !loadingSupplies">
                                        {{getCantidadDeAbastecimientos()}} lts. sin compensar
                                    </h3>
                                    <h3  v-if="getCantidadCompDeAbastecimientos() != false && !loadingSupplies">
                                        {{sumSuppliesSelected.toLocaleString('de', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }} lts. A 15ºC
                                    </h3>
                                    <b-skeleton v-if="loadingSupplies"></b-skeleton>
                                    <h3 v-if="getCantidadDeAbastecimientos() == false && getCantidadCompDeAbastecimientos() == false && !loadingSupplies">
                                        No se vincularon abastecimientos.
                                    </h3>
                                </b-card>
                                
                                <b-card :class="getResultVariant == 'good' ? 'cards-confirmation border border-success': 'cards-confirmation border border-warning'" v-if="getCantidadDeAbastecimientos() != false && getCantidadDeItems() != false || getCantidadCompDeAbastecimientos() != false && getCantidadCompDeItems() != false " :bg-variant="getResultVariant" :text-variant="getResultVariant == 'success' ? 'light' : 'dark'">
                                    <b-card-title>Diferencia: </b-card-title>
                                    <h3 v-if="getCantidadDeAbastecimientos() != false && getCantidadDeItems() != false">
                                        {{(getCantidadDeItems() - getCantidadDeAbastecimientos()).toLocaleString('de', {minimumFractionDigits: 2, maximumFractionDigits: 2})}} lts. sin compensar
                                    </h3>
                                    <h3  v-else-if="getCantidadCompDeAbastecimientos() != false && getCantidadCompDeItems() != false">
                                        {{(getCantidadCompDeItems() - sumSuppliesSelected ).toLocaleString('de', {minimumFractionDigits: 2, maximumFractionDigits: 2})}} lts. a 15°
                                    </h3>
                                </b-card>
                            </b-card-group>
                            <b-form-group
                                class="mt-2">
                                <label 
                                style="color:rgba(0, 0, 0, 0.8);font-size:14px"
                                for="comment">Añadir Comentario (opcional)  </label>
                                <b-form-textarea
                                id="comment"
                                v-model="form.comment"
                                rows="3"
                                max-rows="3"
                                ></b-form-textarea>
                            </b-form-group>
                    </b-tab>
                </b-tabs>
            </b-form>
            <!-- Control buttons-->
            <template #modal-footer class="text-center">
                    <b-button v-if="tabIndex > 0 && tabItems != 'agregar-item' && tabItems != 'vincular' " variant="outline-primary" @click="tabIndex--">Anterior</b-button>
                    <b-button v-if="tabIndex == 1" @click="tabIndex++" variant="outline-primary">Siguiente</b-button>
                    <b-button v-if="tabItems == 'agregar-item' || tabItems == 'vincular'" @click="agregarItemMenu('tabla')" variant="outline-primary">Cancelar</b-button>
                    <b-button v-if="tabItems == 'agregar-item'" @click="agregarItem" variant="outline-primary">{{tabEditItem ? 'Editar' : 'Agregar'}}</b-button>
                    <b-button v-if="tabItems == 'vincular'" @click="vincular()" variant="outline-primary" :disabled="disableNextPage">Aceptar</b-button>
                    <b-button v-if="tabIndex != 1 && tabItems != 'agregar-item' && tabItems != 'vincular' && tabIndex != 3" @click="tabIndex++" variant="outline-primary" :disabled="disableNextPage">Siguiente</b-button>
                    <b-button v-if="tabIndex == 3 && isCreating" variant="success" @click="finalizarCreacion">{{finishButton}}</b-button>
                    <b-button v-if="tabIndex == 3 && !isCreating" variant="success" @click="finalizarEdicion">{{finishButton}}</b-button>
                <!-- <div class="text-muted">Current Tab: {{ tabIndex }}</div> -->
                <!-- <div>{{supply_relation_array}}</div>  -->
            </template>
        </b-modal>

    </b-container>
    </b-overlay>
</template>
<script>
import axios from 'axios';
axios.defaults.withCredentials = true;
export default {
    props: {
        isMenor: Boolean,
        isMobile: Boolean,
        token: String,
        endpoint_api: String,
        permissions: Array,
        isAdmin: Boolean,
        canal: Object
    },
    title: 'Administración de Remitos | Abastecimientos | ',
    data() {
        return {
            index:null,
            sin_editar: [],
            afterTab2: true,
            tabItems: 'tabla',
            nombre_operador : null,
            supply_relation_array:[],
            options_proveedores:[],
            alerta_warning: true,
            disabledSelect:false,
            selected:[],
            defaultSelect:"",
            defaultSelectOperador:"",
            errorCantidad: false,
            config: {},
            show:true,
            tituloModal: 'Crear Remito',
            finishButton: 'Crear Remito',
            titleItemForm: 'Agregar Item',
            errorProducto: false,
            manual_supply_id: '',
            base_uri: '/manual',
            manual_supplies: [],
            isCreating: true,
            itemsIndex: -1,
            fields: [
                {key:'fecha_hora', label:"Fecha", sortable: true},
                {key:'nro_remito', label:"Remito N°", sortable: true, class:"overflow-hidden fields-table"},
                {key:'proveedor', sortable: true, class:"overflow-hidden fields-table"},
                {key:'cuit', label:"CUIT", sortable: true},
                {key:'operador', label:"Operador", sortable: true, class:"overflow-hidden fields-table"},
                // {key:'image_url', label:"Imagen", sortable: true, class:"overflow-hidden fields-table"},
                {key: 'actions', label: 'Acciones'}
            ],
            busy: false,
            tokenDefeated: false,
            tabEditItem:false,
            otherErr:false,
            modal_esc:true,
            textErr:'',
            error:false,
            extra: {
                permissions: [],
                equipos: [],
                productos: []
            },
            floatRegex: /^\d*(\.\d{0,2})?$/,
            tabIndex: 0,
            form: {
                nro_remito: '',
                proveedor: '',
                fecha:"",
                operador: {id_usuario_fuelsentry: null, nombre_completo: null},
                hora: '',
                cuit: '',
                comment: ''
            },
            editando : false,
            items: [],
            formItem: {
                id_tanque: '',
                tank_site_id: '',
                producto: '',
                producto_descripcion:'',
                producto_densidad: '',
                producto_name: '',
                coef_var_vol: '',
                cantidad: '',
                temp: ''
            },
            options_productos: [],
            options_equipos: [],
            options_operadores: [],
            bindable_supplies: [],
            possible_supplies: [],
            binded_supplies_to_load: [],
            current_binded_supplies_data: [],
            fields_supplies: [
                {key:'tank_site_id', label:"Tanque", sortable:true},
                {key:'fecha_hora_start', label:"Fecha", sortable: true},
                {key:'site_name', label:"Sitio", sortable: true},
                {key:'producto_name', label:"Producto", sortable: true},
                {key: 'cantidad_comp', label: "Cantidad @15°C", sortable: true},
                {key: 'selected', label: 'Seleccionado', sortable:true},
            ],
            fields_items: [
                {key:'producto_name', label:"Producto", sortable: true},
                {key: 'cantidad', label: "Cantidad (en lts.)", sortable: true},
                {key: 'temp', label: "Temperatura (C°)", sortable: true},
                {key: 'actions', label: 'Acciones', thClass:"text-right"}
            ],
            filter:null,
            msgBindedSupplies: '',
            detailRowFields: [
                {key:'producto_name', label:"Producto", sortable: true},
                {key: 'cantidad', label: "Cantidad", sortable: true},
                {key: 'temp', label: "Temperatura", sortable: true},
            ],
            activateAditionalTab: false,
            activateBindTab: false,
            activateFinishTab: false,
            activateAddItem:false,
            loadingSupplies: true,
            disableProductos: true,
            alreadyEdited: false,
            currentItem: '',
        }
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.setTitle(vm.canal.canal);
            vm.$emit('mostrarDash', true);           
        });
    },
    watch: {
        'canal.canal' (val) {
            this.setTitle(val);
        },
        'tabIndex' (val) {
            if (val !==2) this.tabItems = 'tabla';
        },
        'loadingSupplies' (val) {
            if (!val) {
                this.current_binded_supplies_data = this.items.map(e => this.getLinked(e));
                this.supply_relation_array = this.current_binded_supplies_data;
            }
        }
    },
    methods: {
        //cambia la pestaña entre agregar item y el listado de items
        agregarItemMenu(parametro){
            this.deleteState();
            this.tabItems = parametro;
            this.tabEditItem = false;
            this.selected = [];
        },
        deleteState(){
            this.alerta_warning = true;
            this.errorProducto = false;
            this.errorCantidad = false;
        },
        vinculado(item){
            if(item.item._id == undefined){
                if(this.supply_relation_array[item.index]){
                    if (this.supply_relation_array[item.index].length != 0){
                        return true
                    } else {
                        return false
                    }
                }
                else {
                    return false;
                }
            } else {
                return this.bindable_supplies.some(e => e.id_manual_supply_item == item.item._id);
            }
        },
        vincular(){
            this.alreadyEdited = true;
            this.updateBindedSupplies(this.currentItem, this.selected, this.index);  
            // return
            /*Elimina los abastecimientos seleccionados de otros indices*/
            //abastecimientos en arreglo
            //TODO debería ser sobre items.length? no se entera si agregas un item
            if(this.supply_relation_array.length != 0 && this.selected.length != 0){
                for(var select = 0; select < this.selected.length; select++){
                    //arreglo de arreglos
                    for(var index = 0; index < this.supply_relation_array.length; index++){
                        //arreglo index = index
                        //abastecimientos
                        for(var supply = 0; supply < this.supply_relation_array[index].length; supply++){
                            if(this.supply_relation_array[index][supply]._id == this.selected[select]._id){
                                this.supply_relation_array[index].splice(supply,1)
                                break;
                            }
                        }
                    }
                }
            }
            this.$set(this.supply_relation_array, this.index, this.selected);
            this.tabItems = 'tabla';
            this.selected = [];
        },
        openBindTab(index){
            if (!this.alreadyEdited) {
                this.supply_relation_array = this.current_binded_supplies_data; 
            }
            this.index = index.index;
            this.findLinked(index.item)
            this.tabItems = 'vincular';
            this.currentItem = Object.assign({}, this.items[index.index]);
            let supplies_of_item;
            if(this.currentItem._id){
                supplies_of_item = this._.filter(this.bindable_supplies, {id_manual_supply_item: this.currentItem._id});
            } else {
                supplies_of_item = this._.filter(this.bindable_supplies, {id_manual_supply_item: this.index});
            }
            this.selected = supplies_of_item;
            this.loadPossibleSuppliesFromItem(this.currentItem, supplies_of_item);
            this.items[index.index].edited = true;
        },
        agregarValorInput(e){
            if(e == null){
                this.form.proveedor = ''
                this.form.cuit = ''
            } else{
            this.form.proveedor = e.proveedor;
            this.form.cuit = e.cuit;
            }
        },
        agregarValorInputOperador(e){
            if(e == null){
                this.form.operador.id_usuario_fuelsentry = null
            } else{
                this.form.operador.id_usuario_fuelsentry = e.id_usuario_fuelsentry;
                this.nombre_operador = null
            }
        },
        insertDefault(){
            if(this.form.proveedor === ''){
                this.defaultSelect = 'Seleccionar';
            } else {
                this.defaultSelect = this.form.proveedor;
            }
        },
        updateBindedSupplies(item,seleccionados,indice){
            this.bindable_supplies = this.bindable_supplies.map(function(abastecimiento){
                if((abastecimiento.id_manual_supply_item!=null) && (abastecimiento.id_manual_supply_item == item._id) && (abastecimiento.id_manual_supply_item!= undefined)
                || (abastecimiento.id_manual_supply_item!=null) && (abastecimiento.id_manual_supply_item == indice) && (abastecimiento.id_manual_supply_item!= undefined)){
                    if (!seleccionados.length) {
                        abastecimiento.id_manual_supply_item = null
                    }
                    var vinculado = false;
                    for(var select = 0; select < seleccionados.length; select++){
                        if(abastecimiento._id == seleccionados[select]._id){
                            vinculado = true;
                            if(item._id){
                                abastecimiento.id_manual_supply_item = item._id;
                            } else {
                                abastecimiento.id_manual_supply_item = indice;
                            }
                        }
                    }
                    if(vinculado == false){
                        abastecimiento.id_manual_supply_item = null
                    }
                } else {
                    for(var index = 0; index < seleccionados.length; index++){
                        if(abastecimiento._id == seleccionados[index]._id){
                            if(item._id){
                                abastecimiento.id_manual_supply_item = item._id;
                            } else {
                                abastecimiento.id_manual_supply_item = indice
                            }
                        }
                    }
                }
                return abastecimiento
            })
            return;
        },
        insertDefaultOperadores(){
            if(this.form.operador.id_usuario_fuelsentry !== null){
                this.defaultSelectOperador = this.findOperador(this.form.operador);
            }else {
                this.defaultSelectOperador = 'Seleccionar';
            }
        },
        rowClicked(item) {
            if(item.selected){    //desmarcar
                this.$set(item, 'selected', false)
                var index = this.selected.indexOf(item);
                this.selected.splice(index, 1);
            }else{ //marcar
                this.$set(item, 'selected', true)
                // let id = this.currentItem._id ? this.currentItem._id : this.index;
                // this.$set(item, 'id_manual_supply_item', id) //guarda la posicion del item si lo estas por crear si no guarda el id (si estas actualizando)
                this.selected.push(item)
            }
        },
        tbodyRowClass(item) {
            if (!item) return ["no-events"];
            /* Style the row as needed */
            if (this.modoReadOnly) {
                return ["no-events"];
            }
            if(item.selected){
            return ["b-table-row-selected", "table-success", "cursor-pointer"]
            } else {
            return ["cursor-pointer"]
            }
        },
        deleteRemito(item) {
            let optionsModal = {
                title: 'Eliminar Remito',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'danger',
                headerClass: 'p-2 border-bottom-0',
                footerClass: 'p-2 border-top-0',
                centered: true
            };
            this.$bvModal.msgBoxConfirm(`¿ Desea eliminar el remito N° ${item.nro_remito} ?`, optionsModal)
                .then((value) => {
                    if (value) {
                        let uri = `${process.env.VUE_APP_ENDPOINT_API}/manual/${item._id}`
                        axios.delete(uri,this.config)
                            .then(() => {
                                this.mensaje(`El remito numero ${item.nro_remito} fue eliminado`, 'success', 'Remito eliminado con exito');
                                this.getManualSupplies();
                            })
                            .catch(error => {
                                if (error.response) {
                                    this.error = error.response.data.msg;  
                                    if (error.response.status == 401){
                                        this.text = "Para ver los datos debe reiniciar sesión en la plataforma."
                                    }
                                } else {
                                    this.otherErr = true;
                                    this.textErr = error;
                                }
                                this.mensaje(this.error,'danger',`Hubo un problema eliminado el registro ${item.nro_remito}`);
                            });
                    }
                }).catch(e => console.log(e));

            return;
        },

        getProveedores(){
            this.config = {
                headers: {
                    Authorization: this.token
                }
            }
            axios 
            .get(`${process.env.VUE_APP_ENDPOINT_API}/suppliers`, this.config)
            .then((response) => {
                this.options_proveedores = response.data.data;
            })
            .catch( error => {
                this.defaultSelect='hubo un problema';
                this.disabledSelect=true;
                console.log(error)
            })
        },

        getManualSupplies() {
            this.busy = true;
            this.config = {
                headers: {
                    Authorization: this.token
                }
            };
            return axios
            .get(process.env.VUE_APP_ENDPOINT_API + this.base_uri, this.config)
            .then( (response) => {
                this.options_operadores = response.data.extra.operadores;
                response.data.data.forEach(element => {
                    if(element.operador.nombre_completo !== null){
                        this.options_operadores.push(element.operador)
                    }
                });
                this.manual_supplies = response.data.data;
                console.log(response.data.data, 'manual supplies')
                // this.manual_supplies = response.data.data.map(function(item) {
                //     if(item.image){
                //         const buffer = Buffer.from(item.image.data)
                //         const blob = new Blob([buffer], { type: item.image.contentType });
                //         item.image_url = URL.createObjectURL(blob);
                //         console.log(item, 'item con imagen')
                //         return item
                //     } else {
                //         return item
                //     }
                // });
                this.extra.equipos = response.data.extra.equipos;
                this.extra.productos = response.data.extra.productos;
                this.options_equipos = this._.uniqBy(response.data.extra.productos, 'id_equipo');
                this.options_equipos = this._.sortBy(this.options_equipos, 'equipo');
                this.options_equipos = this.options_equipos.map(e => {
                    e.equipo = e.equipo + ' - '+ e.id_equipo;
                    return e;
                });
                this.options_operadores = response.data.extra.operadores;
                this.busy = false;
                this.updateShow(false);
            })
            .catch( error => {
                if (error.response) {

                    if (error.response.status == 401){
                        this.tokenDefeated=true;
                    } else {
                        this.textErr = error.response.data.msg
                        this.otherErr = true;
                    }
                    
                } else {
                    this.textErr = error;
                    this.otherErr = true;
                }
                this.busy = false;
                this.updateShow(false);
            })

        },
        createManualSupply() { //TODO unificar con editManualSupply
            let uri = `${process.env.VUE_APP_ENDPOINT_API}/manual/`;

            this.config = {
                headers: {
                    Authorization: this.token
                }
            };

            if(this.form.hora == ''){
                this.form.hora = '00:00'
            }
            this.form.operador.nombre_completo = this.nombre_operador
            if(this.form.operador.nombre_completo ==! null || this.form.operador.nombre_completo ==! ""){
                this.form.operador.id_usuario_fuelsentry = null
            }

            let manualSupply = {
                nro_remito: this.form.nro_remito,
                select_proveedor: this.form.select_proveedor,
                proveedor: this.form.proveedor,
                operador: this.form.operador,
                fecha_hora: `${this.form.fecha} ${this.form.hora}`,
                cuit: this.form.cuit,
                comment: this.form.comment
            }

            manualSupply.items = this.items;
            manualSupply.binded_supplies = this.supply_relation_array.map(a => a.map(e => e._id)).flat()
            manualSupply.binded_items = this.supply_relation_array.map(a => a.map(this.setItemIdOrIndex)).flat();


            axios.post(uri, manualSupply, this.config)
            .then( (response) => {
                response.data.text = 'Ok';
                this.mensaje(`El remito N° ${manualSupply.nro_remito} fue creado correctamente.`, 'success', 'Remito Creado');
                this.getManualSupplies();
            } )
            .catch( error => {
                let variant = 'danger';
                if (error.response) {
                    this.error = error.response.data.msg;  
                    if (error.response.status == 401){
                        this.text = "Para ver los datos debe reiniciar sesión en la plataforma.";
                        this.tokenDefeated = true;
                    }

                    if (error.response.data.err_code == 'ERR_FAIL_SUPPLY_RELATION_UPDATE') {
                        this.otherErr = true;
                        this.error = "El remito fue creado pero hubo un problema al vincularlo con los abastecimientos registrados";
                        variant = 'warning';
                    }
                } else {
                    this.otherErr = true;
                    this.error = error;
                }
                this.mensaje(this.error,variant,`Hubo un problema creando el remito ${this.form.nro_remito}`);
                this.getManualSupplies();
            })
            return;
        },
        activateTabAddItem(){
            this.activateTabs('activateAddItem',true);
        },
        setItemIdOrIndex(e) {
            let isMongoId = false;
            if (e.id_manual_supply_item){
                isMongoId = e.id_manual_supply_item.length === 24;
            }
            return {
                id_supply: e._id, 
                id_item: isMongoId ? e.id_manual_supply_item : undefined,
                index: isMongoId ? undefined : e.id_manual_supply_item
            }
        },
        editManualSupply() {
            let uri = `${process.env.VUE_APP_ENDPOINT_API}/manual/${this.manual_supply_id}`;

            this.config = {
                headers: {
                    Authorization: this.token
                }
            };
            
            let nro = this.form.nro_remito;
            this.form.operador.nombre_completo = this.nombre_operador
            if(this.form.operador.nombre_completo !== null && this.form.operador.nombre_completo !== ""){
                this.form.operador.id_usuario_fuelsentry = null
            }
            if(this.form.operador.id_usuario_fuelsentry !== null) {
                this.form.operador.nombre_completo = null 
            }

            let manualSupply = {
                proveedor: this.form.proveedor,
                operador:this.form.operador,
                fecha_hora: `${this.form.fecha} ${this.form.hora}`,
                cantidad_remito: this.form.cantidad_remito,
                cuit: this.form.cuit,
                comment: this.form.comment
            }

            manualSupply.items = this.items;
            manualSupply.binded_supplies = this.supply_relation_array.length ? 
                this.supply_relation_array.map(a => a.map(e => e._id)).flat() :
                this.current_binded_supplies_data.map(a => a.map(e=>e._id)).flat();
            manualSupply.binded_items = this.supply_relation_array.length ? 
                this.supply_relation_array.map(a => a.map(this.setItemIdOrIndex)).flat() :
                this.current_binded_supplies_data.map(a => a.map(this.setItemIdOrIndex)).flat();


            axios.put(uri, manualSupply, this.config)
            .then( (response) => {
                response.msg = 'ok';
                this.mensaje(`El remito N° ${nro} fue editado correctamente.`, 'success', 'Remito Editado');
                this.getManualSupplies();
            } )
            .catch( error => {
                console.log(error)
                var variant = 'danger';
                if (error.response) {
                    this.error = error.response.data.msg;  
                    if (error.response.status == 401){
                        this.text = "Para ver los datos debe reiniciar sesión en la plataforma.";
                        this.tokenDefeated = true;
                    }

                    if (error.response.data.err_code == 'ERR_FAIL_SUPPLY_RELATION_UPDATE') {
                        this.error = "El remito fue editado pero hubo un problema al vincularlo con los abastecimientos registrados";
                        variant = 'warning';
                    }
                } else {
                    this.error = error;
                }
                this.mensaje(this.error,variant,`Hubo un problema editando el remito ${this.form.nro_remito}`);
                this.getManualSupplies();
            })
        },
        finalizarCreacion() {
            this.createManualSupply();
            this.$bvModal.hide('modal-crear-remito');
            this.emptyForm();
            this.emptyFormItem();
            return;
        },
        finalizarEdicion() {
            this.editManualSupply();
            this.$bvModal.hide('modal-crear-remito');
            this.emptyForm();
            this.emptyFormItem();
            return;
        },
        mensaje(msg,variant = null, title = null){
            this.$bvToast.toast(msg, {
                title: title != null ? title : null,
                variant: variant,
                solid: true
            })
        },
        updateProductOptions(equipo) {
            this.options_productos = this._.filter(this.extra.productos, {id_equipo: equipo.id_equipo});
            this.disableProductos = false;
            this.formItem.producto = this.options_productos[0];
        },
        async agregarItem() {
            if (this.formItem.producto == '' || this.formItem.producto === null) {
                this.alerta_warning = false;
                this.errorProducto = true;
                return;
            }
            if(this.isValidCant == false){
                this.alerta_warning = false;
                this.errorCantidad = true;
                return;
            }

            let producto = this.formItem.producto;
            this.formItem.producto_densidad = producto.density;
            this.formItem.coef_var_vol = producto.coef_var_vol;
            this.formItem.producto_name = producto.nombre_producto;
            this.formItem.id_equipo = producto.id_equipo;
            this.formItem.producto = producto.producto;
            this.formItem.producto_code = producto.codigo;

            if (this.itemsIndex != -1) {
                this.$set(this.items, this.itemsIndex, this.formItem);
            } else {
                // this.$set(this.formItem, "_id", this.items.length);
                this.items.push(this.formItem);
            }
            this.emptyFormItem();
            this.tabItems= 'tabla'
        },
        deleteItem(index) {
            this.items.splice(index.index,1);
            this.supply_relation_array[index.index] = []
        },
        editItem(index) {
            this.tabEditItem = true;
            index = index.index;
            this.titleItemForm = 'Editar Item';
            this.itemsIndex = index;
            this.fillFormItem(this.items[index]);
            this.errorProducto = false;
            this.tabItems = 'agregar-item';
        },
        cancelar() {
            this.deleteState();
            this.emptyFormItem();
            this.$bvModal.hide('modal-nuevo-item');
        },
        async editar(item, isRouteNav = false){
            this.editando = true;
            this.tituloModal = "Editar Remito N° "+item.nro_remito;
            this.finishButton = 'Finalizar edición';
            this.isCreating = false;
            this.manual_supply_id = item._id;
            this.fillForm(item);
            this.nombre_operador = this.form.operador.nombre_completo;
            this.getProveedores();
            this.insertDefault();
            this.insertDefaultOperadores();
            this.activateTabs('activateFinishTab', true);
            if (isRouteNav) {
                this.getSuppliesToBind(1);
                this.tabIndex = 3;
                this.activateTabs('activateAditionalTab', true);
                this.activateTabs('activateBindTab', true);
                this.activateTabs('activateFinishTab', true);
            }
        },
        crear() {
            this.editando = false;
            this.tituloModal = 'Crear Remito';
            this.finishButton = 'Crear Remito';
            this.getProveedores();
            this.insertDefault();
            this.insertDefaultOperadores();
            this.isCreating = true;
            this.$bvModal.show('modal-crear-remito');
        },
        fillForm(item) {
            let fecha_hora = new Date(item.fecha_hora).toLocaleString('pt' ,{dateStyle: 'short',timeStyle: 'short'})
            let hora = fecha_hora.split(' ')[1];

            this.form = {
                nro_remito: item.nro_remito,
                proveedor: item.proveedor,
                operador: item.operador,
                fecha: item.fecha_hora.split('T')[0],
                hora: hora,
                cuit: item.cuit.toString(),
                comment: item.comment
            }

            this.items = item.items;
            this.$bvModal.show('modal-crear-remito');
        },
        fillFormItem(item) {
            this.formItem = {
                id_tanque: item.id_tanque ? item.id_tanque : '',
                tank_site_id: item.tank_site_id ? item.tank_site_id : '',
                id_equipo: item.id_equipo ? this.getEquipo(item) : '',
                producto: item.producto !== '' ? this.getProducto(item) : '',
                producto_descripcion:item.producto_descripcion ? item.producto_descripcion : '',
                producto_densidad: item.producto_densidad ? item.producto_densidad : '',
                producto_name: item.producto_name ? item.producto_name : '',
                producto_code: item.producto_code ? item.producto_code : '',
                coef_var_vol: item.coef_var_vol ? item.coef_var_vol : '',
                cantidad: item.cantidad ? item.cantidad : '',
                cantidad_comp: item.cantidad_comp ? item.cantidad_comp : '',
                temp: item.temp ? item.temp : '',
                tipo_de_carga: item.tipo_de_carga ? item.tipo_de_carga : ''
            }
            if (this.formItem.producto != '') this.disableProductos = false;
            // TODO no mostrar equipo en los remitos creados antes del cambio de modelo xq no tiene sentido
        },
        modalConfirmar(){
                this.$bvModal.msgBoxConfirm('Al cerrar el modal se perderan todos los cambios realizados. ¿Desea continuar?', {
                    size: 'sm',
                    buttonSize: 'sm',
                    noCloseOnEsc:true,
                    okVariant: 'danger',
                    okTitle: 'Cerrar de todos modos',
                    cancelTitle: 'Volver',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })            
                .then(value => {
                if(value){
                    if(this.editando){
                        this.emptyFormItem();
                        this.emptyForm();
                        this.getManualSupplies();
                    }else{
                        this.emptyFormItem();
                        this.emptyForm();
                    }
                    this.$bvModal.hide('modal-crear-remito');
                }
                })
                .catch(err => {
                    console.log(err)
                })
        },
        hide(bvModalEvent){
            if(bvModalEvent.trigger == 'backdrop' || bvModalEvent.trigger == 'esc'){
                bvModalEvent.preventDefault();
                this.modalConfirmar()
            }
        },
        close(bvModalEvt) {
            bvModalEvt.preventDefault()
            this.modalConfirmar()
        },
        emptyForm(){
            this.defaultSelect = ''
            this.manual_supply_id = '';
            this.form = {
                nro_remito: '',
                proveedor: '',
                operador:{id_usuario_fuelsentry:null, nombre_completo:null},
                fecha:"",
                hora: '',
                cuit: '',
                comment: ''
            }
            this.form.operador.id_usuario_fuelsentry = null
            this.form.operador.nombre_completo = null,
            this.items = [];
            this.nombre_operador = null
            this.afterTab2 = true;
            this.alreadyEdited = false;
            this.tabIndex = 0;
            this.tabItems = "tabla";
            this.supply_relation_array = [];
            this.selected = []
            this.possible_supplies = [];
            this.deactivateTabs();
        },
        findOperador(item){
            try{
                if(item.id_usuario_fuelsentry !== null){
                    var operador = this.options_operadores.find(element => element.id_usuario_fuelsentry == item.id_usuario_fuelsentry)
                    return operador.nombre_completo
                }
                else {
                    return 'No se indicó firmante'
                }
            }
            catch(err){
                console.log(err)
                return 'ha ocurrido un error al obtener el operador'
            }
        },
        emptyFormItem() {
            this.title = 'Agregar item';
            this.itemsIndex = -1;
            this.errorProducto = false;
            this.errorCantidad = false;

            this.formItem = {
                site_name: '',
                id_tanque: '',
                id_equipo: '',
                tank_site_id: '',
                producto: '',
                producto_descripcion:'',
                producto_densidad: '',
                producto_name: '',
                coef_var_vol: '',
                cantidad: '',
                temp: '',
            }
        },
        getProducto(item) {
            try {
                let needle = {
                    producto: item.producto.toString(), 
                    id_equipo: item.id_equipo.toString()
                }
                let producto = this._.find(this.options_equipos, needle);
                return producto;
                
            } catch (error) {
                return item.producto;
            }
        },
        loadPossibleSuppliesFromItem (item, selected = []) {
            if (item) {
                this.possible_supplies = this._.filter(this.bindable_supplies, {id_equipo: parseInt(item.id_equipo), producto_name: item.producto_name});
                this.possible_supplies.concat(selected);
                this.possible_supplies = this._.uniq(this.possible_supplies);
                this.msgBindedSupplies = this.possible_supplies.length ? '' : "No se encontraron abastecimientos."
            }
        },
        findLinked(item){
            this.bindable_supplies = this.bindable_supplies.map((supply)=>{
                if(supply.id_manual_supply_item == item._id && supply.id_manual_supply_item != undefined || (supply.id_manual_supply_item == this.index && supply.id_manual_supply_item != undefined)){
                    supply.selected = true;
                } else{
                    supply.selected = false;
                }
                return supply
            })
            return;
        },
        getLinked(item){
            return this.bindable_supplies.filter(supply => {
                return supply.id_manual_supply_item == item._id && supply.id_manual_supply_item != undefined
            })
        },
        getEquipo(item) {
            try {
                let needle = {
                    id_equipo: item.id_equipo.toString()
                }
                let equipo = this._.find(this.options_equipos, needle);
                return equipo;
                
            } catch (error) {
                return item.id_equipo;
            }
        },
        getSuppliesToBind(caller) {
            console.log(caller);
            this.afterTab2 = false;
            this.msgBindedSupplies = ''; 
            this.loadingSupplies = true;          
            const earlyDate = new Date(this.form.fecha);
            const lateDate = new Date(this.form.fecha);
            this.config = {
                headers: {
                    Authorization: this.token
                },
                params: {
                    desde: new Date(earlyDate.setDate(earlyDate.getDate()-3)),
                    hasta: new Date(lateDate.setDate(lateDate.getDate()+3))
                }
            };

            return axios
            .get(process.env.VUE_APP_ENDPOINT_API, this.config)
            .then( (response) => {
                this.bindable_supplies = response.data.data;
                if (this.bindable_supplies.length == 0) {
                    this.msgBindedSupplies = 'No se encontraron abastecimientos';
                }
                let a = this._.filter(this.bindable_supplies, { id_manual_supply: { _id:this.manual_supply_id }});
                this.binded_supplies_to_load = a;
                //TODO vincular solo con equipo seleccionado en item anterior
                this.loadingSupplies = false;
                this.currentItem = this.items[0];
            })
            .catch( error => {
                console.log(error)
                if (error.response) {
                    this.msgBindedSupplies = error.response.data.msg;  
                    if (error.response.status == 401){
                        this.msgBindedSupplies = "Para ver los datos debe reiniciar sesión en la plataforma.";
                        this.tokenDefeated = true;
                    }

                } else {
                    this.msgBindedSupplies = 'Hubo un problema';
                }
                this.loadingSupplies = false;

            })
        },
        activateTabs(tab, val) {
            this[tab] = val;
        },
        updateErrorCantidad() {
            if (this.formItem.cantidad == '' || this.formItem.producto === null) {
                this.errorCantidad = true;
            } else {
                this.errorCantidad = false;
            }
            return;
        },
        clearSelected(){
            this.selected = [];
        },
        deactivateTabs(){
            this.activateAditionalTab = true;
            this.activateBindTab = false;
            this.activateFinishTab = false;
            this.afterTab2 = true;
        },
        updateErrorProducto() {
            if (this.formItem.producto == '' || this.formItem.producto === null) {
                this.errorProducto = true;
            } else {
                this.errorProducto = false;
            }
            return;
        },
        getCantidadDeItems() {
            let cantidad = this.items.map(e => parseFloat(e.cantidad));
            if (cantidad.length == 0) return false;
            if (cantidad.every(e => !isNaN(e))) {
                let val = cantidad.reduce((accumulator, currentValue) => accumulator + currentValue)
                return val.toFixed(2);
            } 
            return false;
        },
        getCantidadCompDeItems() {
            for (const i of this.items) {
                if (!i.temp) {
                    return false;
                }
            }
            var itemsCompSum = 0;
            var itemComp;
            for (const item of this.items) {
              itemComp = item.cantidad / 1 + item.coef_var_vol * (item.temp - 15);
              console.log(itemComp)
              itemsCompSum += itemComp;
              console.log(itemsCompSum)
            }
            return itemsCompSum.toFixed(2);
        },
        getCantidadDeAbastecimientos() {
            if (this.supply_relation_array.length === 0) return false;
            let cantidad = this.supply_relation_array.map(a => a.map(e => parseFloat(e.cantidad)));
            if (cantidad.length == 0) return false;
            cantidad = cantidad.flat();
            if (cantidad.length && cantidad.every(e => !isNaN(e))) {
                let val = cantidad.reduce((accumulator, currentValue) => accumulator + currentValue)
                return val.toFixed(2);
            }
            return false;
        },
        getCantidadCompDeAbastecimientos() {
            if (this.supply_relation_array.length === 0) return false;
            let cantidad_comp = this.supply_relation_array.map(a => a.map(e => parseFloat(e.cantidad_comp)));
            if (cantidad_comp.length == 0) return false;
            cantidad_comp = cantidad_comp.flat();
            if (cantidad_comp.length && cantidad_comp.every(e => !isNaN(e))) {
                let val = cantidad_comp.reduce((accumulator, currentValue) => accumulator + currentValue)
                return val.toFixed(2);
            } 
            return false;
        },
        updateShow(show) {
            this.show = show;
        },
        parseCUIT(val) {
            var txt2 = val.slice(0, 2) + "-" + val.slice(2, -1) +'-' + val.slice(-1);
            return txt2;
        },
    },
    async mounted() {
        await this.getManualSupplies();

        if (this.$route.query) {
            if (this.$route.query.modal){
                this.crear();
            }
        }

        if (this.$route.params) {
            if (this.$route.params.id_manual_supply) {
                let _id = this.$route.params.id_manual_supply;
                let item = this._.find(this.manual_supplies, { _id });
                if (item != undefined) {
                    this.editar(item, true);
                } else {
                    this.mensaje('El remito al que intenta acceder no existe', 'warning', 'Hubo un problema');
                }
            }
        }
    },
    computed: {
        hasManualSupplies() {
            return this.manual_supplies.length > 0;
        },
        countSuppliesSelected() {
            return this.selected.length;
        },
        sumSuppliesSelected() {
            let accum =  0;
            this.selected.forEach( e => {
                accum+= e.cantidad_comp;
            });
            return accum;
        },
        labeledItems() {
            return this.items.map((e, i) => {
                e.label = `${i+1} - ${e.producto_name} (${e.cantidad} lts)`;
                return e;
            })
        },
        getResultVariant() {
            if (this.loadingSupplies) return 'light';
            let a,b,c,d;
            a = this.getCantidadDeItems();
            b = this.getCantidadDeAbastecimientos();
            c = this.getCantidadCompDeItems();
            d = this.sumSuppliesSelected;
            let cant = a != false && b != false ? a - b : false;
            let cantComp = c != false && d != false ? c - d : false;
            if (cant != false) {
                let maxPorc = Math.max(a,b) / 100 * 5;
                return maxPorc >= Math.abs(cant) ? 'good' : 'purple';
            }

            if (cantComp != false) {
                let maxCompPorc = Math.max(a,b) / 100 * 5;
                return maxCompPorc >= Math.abs(cantComp) ? 'good' : 'purple';
            }

            return 'light';
        },
        tienePermisoCreate() {
            let manual = this._.find(this.permissions, {objeto: 'MANUAL_SUPPLY', tipo:'CREATE'}) == undefined ? false : true;
            let manual_canal = this._.find(this.permissions, {objeto: 'MANUAL_SUPPLY_CANAL', tipo:'CREATE'}) == undefined ? false : true;
            return manual || manual_canal;
        },
        tienePermisoRead() {
            let manual = this._.find(this.permissions, {objeto: 'MANUAL_SUPPLY', tipo:'READ'}) == undefined ? false : true;
            let manual_canal = this._.find(this.permissions, {objeto: 'MANUAL_SUPPLY_CANAL', tipo:'READ'}) == undefined ? false : true;
            return manual || manual_canal;
        },
        tienePermisoUpdate() {
            let manual = this._.find(this.permissions, {objeto: 'MANUAL_SUPPLY', tipo:'UPDATE'}) == undefined ? false : true;
            let manual_canal = this._.find(this.permissions, {objeto: 'MANUAL_SUPPLY_CANAL', tipo:'UPDATE'}) == undefined ? false : true;
            return manual || manual_canal;
        },
        tienePermisoDelete() {
            let manual = this._.find(this.permissions, {objeto: 'MANUAL_SUPPLY', tipo:'DELETE'}) == undefined ? false : true;
            let manual_canal = this._.find(this.permissions, {objeto: 'MANUAL_SUPPLY_CANAL', tipo:'DELETE'}) == undefined ? false : true;
            return manual || manual_canal;
        },
        disableNextPage() {
            if (this.tabIndex == 0){
                let firstTab = [
                    this.nroRemitoState,
                    this.proveedorState,
                    this.fechaState,
                    // this.horaState,
                    this.cuitState
                ]
                if (firstTab.every(e => e === true)) {
                    this.activateTabs('activateAditionalTab',false);
                    return false;
                } else {
                    this.activateTabs('activateAditionalTab',true);
                    return true;
                }
            } else if (this.tabIndex == 2) {
                if(this.afterTab2){
                    this.getSuppliesToBind("pido abaste en tab 2");
                }
                if (this.items.length != 0) {
                    this.activateTabs('activateBindTab', true);
                    this.activateTabs('activateFinishTab', true);
                    return false;
                } else {
                    this.activateTabs('activateBindTab', false);
                    return true;
                }
            }

            return true
        },
        nroRemitoState() {
            return this.form.nro_remito.length != '' ? true : null
        },
        cuitState() {
            if (this.form.cuit.trim() == '') {
                return null;
            } else if (this.form.cuit.length !==11){
                return false;
            } else if(this.form.cuit.includes('-')){
                    return false;    
            } else {
                return true;
            }
        },
        proveedorState() {
            return this.form.proveedor.length >= 3 ? true : null
        },
        fechaState() {
            return this.form.fecha.length == 10 ? true : null
        },
        // horaState() {
        //     return this.form.hora.length == 5 ? true : null
        // },
        isValidCant() {
            if (typeof this.formItem.cantidad == 'string'){
                if (this.formItem.cantidad.trim() == '') {
                    return false;
                }
            } 
            return this.floatRegex.test(this.formItem.cantidad);
        },
        isValidTemp() {
            if (typeof this.formItem.temp == 'string'){
                if (this.formItem.temp.trim() == '') {
                    return null;
                }
            }
            return this.floatRegex.test(this.formItem.temp);
        },
        isValidCantComp() {
            if (typeof this.formItem.cantidad_comp == 'string'){
                if (this.formItem.cantidad_comp.trim() == '') {
                    return null;
                }
            }
            return this.floatRegex.test(this.formItem.cantidad_comp);
        },
        isValidDens() {
            if (typeof this.formItem.producto_densidad == 'string'){
                if (this.formItem.producto_densidad.trim() == '') {
                    return null;
                }
            }
            return typeof parseFloat(this.formItem.producto_densidad) == 'number';
        }
        
    }
}
</script>
