<template>
    <b-container>
            <!-- modal editar parametros -->
            <b-modal
            size="lg"
            id="edit-parameters"
            title="Actulizar Parámetros:"
            ok-title="Actualizar"
            cancel-title="Cancelar"
            ok-variant="success"
            @ok="updateParams"
            @cancel="clearForm"
            :ok-disabled="isReadOnly"
            >
            <b-container>
                <b-row>
                    <b-col>
                        <h4>Filtros Aplicados al Tanque:</h4>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <ul class="m-0">
                            <li style=list-style:none v-for="filtro in filters" :key="filtro">
                                <p style="font-size:15px"> ➡ {{filtro}}</p>
                            </li>
                        </ul>
                    </b-col>
                </b-row>
            
            </b-container>
            <b-container class="ml-2 mr-2 mt-2">
                <b-row>
                    <b-col>
                        <b-row>
                            <b-col>
                                <div slot="label" class="d-flex justify-content-between" for="temp_ref">
                                <span>Fecha de Análisis</span>
                                <b-button
                                size="sm"
                                variant="link"
                                pill
                                v-b-tooltip.hover
                                top
                                title="Se va a re-analizar desde la fecha seleccionada y, por lo tanto, los abastecimientos posteriores que hayan sido vinculados a un remito perderán su relación">
                                    <b-icon 
                                    variant ="warning"
                                    icon="exclamation-triangle-fill"
                                    font-scale="1.2"
                                    class="mb-1">
                                    </b-icon>
                                </b-button>
                            </div>
                            </b-col>
                        </b-row>
                        <b-form-group> 
                            <b-form-datepicker 
                            id="analyze_from" 
                            v-model="form.analyze_from"
                            value-as-date 
                            class="mb-2"
                            :disabled="isReadOnly">
                            </b-form-datepicker>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b><h4>Parámetros:</h4></b>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group
                            label-for="id tanque"
                            label="ID tanque:"
                            class="m-2"
                        >
                            <b-row>
                                <b-input-group class="m-2">
                                    <b-form-input
                                        v-model="form.id_tanque"
                                        id="id_tanque"
                                        disabled>
                                    </b-form-input>
                                </b-input-group>
                            </b-row>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group class="m-2">
                            <div slot="label" class="d-flex justify-content-between" for="temp_ref">
                                <span>Temperatura de referencia:</span>
                                <b-button
                                size="sm"
                                variant="link"
                                pill
                                v-b-tooltip.hover
                                top
                                title="Para el cálculo de volumen compensado">
                                    <b-icon 
                                    variant ="secondary"
                                    icon="question-circle-fill"
                                    font-scale=".95"
                                    class="mb-1">
                                    </b-icon>
                                </b-button>
                            </div>
                            <b-row>
                                <b-form-input
                                    v-model="form.temp_ref"
                                    id="temp_ref"
                                    :disabled="isReadOnly">
                                </b-form-input>
                            </b-row>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group class="m-2">
                            <div slot="label" class="d-flex justify-content-between" for="flow_bound">
                                <span>Mínima diferencia:</span>
                                <b-button
                                size="sm"
                                variant="link"
                                pill
                                v-b-tooltip.hover
                                top
                                title="Mínima diferencia de caudal">
                                    <b-icon 
                                    variant ="secondary"
                                    icon="question-circle-fill"
                                    font-scale=".95"
                                    class="mb-1">
                                    </b-icon>
                                </b-button>
                            </div>
                            <b-row>             
                                <b-form-input
                                    v-model="form.flow_bound"
                                    id="flow_bound"
                                    :disabled="isReadOnly">
                                </b-form-input>
                            </b-row>
                        </b-form-group>
                    </b-col>
                    </b-row>
                <b-row>
                    <b-col>
                        <b-form-group class="m-2">
                            <div slot="label" class="d-flex justify-content-between" for="flow_window_minutes">
                                <span>Período:</span>
                                <b-button
                                size="sm"
                                variant="link"
                                pill
                                v-b-tooltip.hover
                                top
                                title="Ventana en minutos para cálculo de caudal">
                                    <b-icon 
                                    variant ="secondary"
                                    icon="question-circle-fill"
                                    font-scale=".95"
                                    class="mb-1">
                                    </b-icon>
                                </b-button>
                            </div>
                            <b-row>
                                <b-form-input
                                    v-model="form.flow_window_minutes"
                                    id="flow_window_minutes"
                                    :disabled="isReadOnly">
                                </b-form-input>
                            </b-row>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group
                            description="Ejemplo: 30min"
                            class="m-2"
                        >
                            <div slot="label" class="d-flex justify-content-between" for="min_time_supply">
                                <span>Minima Duración:</span>
                                <b-button
                                size="sm"
                                variant="link"
                                pill
                                v-b-tooltip.hover
                                top
                                title="Duración mínima de un abastecimiento">
                                    <b-icon 
                                    variant ="secondary"
                                    icon="question-circle-fill"
                                    font-scale=".95"
                                    class="mb-1">
                                    </b-icon>
                                </b-button>
                            </div>
                            <b-row>
                                <b-form-input
                                    v-model="form.min_time_supply"
                                    id="min_time_supply"
                                    :disabled="isReadOnly">
                                </b-form-input>
                            </b-row>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group
                            description="Ejemplo: 30min"
                            class="m-2"
                        >
                            <div slot="label" class="d-flex justify-content-between" for="min_start_point_time">
                                <span>min_start_point_time:</span>
                                <b-button
                                size="sm"
                                variant="link"
                                pill
                                v-b-tooltip.hover
                                top
                                title="Tiempo de búsqueda de mínimo hacia atrás">
                                    <b-icon 
                                    variant ="secondary"
                                    icon="question-circle-fill"
                                    font-scale=".95"
                                    class="mb-1">
                                    </b-icon>
                                </b-button>
                            </div>
                            <b-row>
                                <b-form-input
                                    v-model="form.min_start_point_time"
                                    id="min_start_point_time"
                                    :disabled="isReadOnly">
                                </b-form-input>
                            </b-row>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group
                            class="m-2"
                        >
                            <div slot="label" class="d-flex justify-content-between" for="threshold_supply_tank">
                                <span>Cantidad mínima de litros detectable (%):</span>
                                <b-button
                                size="sm"
                                variant="link"
                                pill
                                v-b-tooltip.hover
                                top
                                title="Define el porcentaje sobre la capacidad del tanque para filtrar abastecimientos chicos">
                                    <b-icon 
                                    variant ="secondary"
                                    icon="question-circle-fill"
                                    font-scale=".95"
                                    class="mb-1">
                                    </b-icon>
                                </b-button>
                            </div>
                            <b-row>
                                <b-form-input
                                    v-model="form.threshold_supply_tank"
                                    id="threshold_supply_tank"
                                    :disabled="isReadOnly">
                                </b-form-input>
                            </b-row>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group class="m-2">
                            <div slot="label" class="d-flex justify-content-between" for="threshold_flow_tank">
                                <span>Umbral de caudal mínino (%):</span>
                                <b-button
                                size="sm"
                                variant="link"
                                pill
                                v-b-tooltip.hover
                                top
                                title="Define el porcentaje sobre la capacidad del tanque para calcular el mínimo caudal -> umbral de caudal en funcion de la capacidad de tanque (expresado como 0.0-1.0)">
                                    <b-icon 
                                    variant ="secondary"
                                    icon="question-circle-fill"
                                    font-scale=".95"
                                    class="mb-1">
                                    </b-icon>
                                </b-button>
                            </div>
                            <b-row>
                                <b-form-input
                                    v-model="form.threshold_flow_tank"
                                    id="threshold_flow_tank"
                                    :disabled="isReadOnly">
                                </b-form-input>
                            </b-row>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group class="m-2">
                            <div slot="label" class="d-flex justify-content-between" for="min_threshold">
                                <span>Límite de diferencia inf.:</span>
                                <b-button
                                size="sm"
                                variant="link"
                                pill
                                v-b-tooltip.hover
                                top
                                title="Límite inferior de diferencia de caudal">
                                    <b-icon 
                                    variant ="secondary"
                                    icon="question-circle-fill"
                                    font-scale=".95"
                                    class="mb-1">
                                    </b-icon>
                                </b-button>
                            </div>
                            <b-row>
                                <b-form-input
                                    v-model="form.min_threshold"
                                    id="min_threshold"
                                    :disabled="isReadOnly">
                                </b-form-input>
                            </b-row>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group class="m-2">
                            <div slot="label" class="d-flex justify-content-between" for="max_threshold">
                                <span>Límite de diferencia sup.:</span>
                                <b-button
                                size="sm"
                                variant="link"
                                pill
                                v-b-tooltip.hover
                                top
                                title="Límite superior de diferencia de caudal">
                                    <b-icon 
                                    variant ="secondary"
                                    icon="question-circle-fill"
                                    font-scale=".95"
                                    class="mb-1">
                                    </b-icon>
                                </b-button>
                            </div>
                            <b-row>
                                <b-form-input
                                    v-model="form.max_threshold"
                                    id="max_threshold"
                                    :disabled="isReadOnly">
                                </b-form-input>
                            </b-row>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group class="m-2">
                            <div slot="label" class="d-flex justify-content-between" for="analysis_method">
                                <span>Método de análisis:</span>
                                <b-button
                                size="sm"
                                variant="link"
                                pill
                                v-b-tooltip.hover
                                top
                                title="DEFAULT: metodo por defecto que analiza el historial punto a punto.
                                    I-U: metodo de detección basado en los codigos I e U">
                                    <b-icon 
                                    variant ="secondary"
                                    icon="question-circle-fill"
                                    font-scale=".95"
                                    class="mb-1">
                                    </b-icon>
                                </b-button>
                            </div>
                            <b-row>
                                <b-form-select 
                                    v-model="form.analysis_method" 
                                    :options="options"
                                    :disabled="isReadOnly"
                                    >
                                </b-form-select>  
                            </b-row>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group class="m-2">
                            <div slot="label" class="d-flex justify-content-between" for="group_microsupplies">
                                <b-form-checkbox id="group_microsupplies" v-model="form.group_microsupplies" name="check-button" switch :disabled="isReadOnly">
                                    <span role="button">
                                        Agrupar microabastecimientos
                                    </span>
                                </b-form-checkbox>
                                <b-button
                                size="sm"
                                variant="link"
                                pill
                                v-b-tooltip.hover
                                top
                                title="Define si agrupar los microabastecimientos (group_microsupplies)">
                                    <b-icon 
                                    variant ="secondary"
                                    icon="question-circle-fill"
                                    font-scale=".95"
                                    class="mb-1">
                                    </b-icon>
                                </b-button>
                            </div>
                            <b-row>
                                <b-input-group size="sm">
                                    <b-input-group-prepend>
                                        <b-input-group-text
                                            size="sm"
                                            variant="outline-primary"
                                            v-b-tooltip.hover
                                            role="button"
                                            :disabled="isReadOnly"
                                            top
                                            title="Intervalo mínimo entre abastecimientos, en segundos, para no ser agrupados">
                                            Tiempo mínimo entre abastecimientos 
                                        </b-input-group-text>
                                    </b-input-group-prepend>
                                    <b-form-input :disabled="!isChecked || isReadOnly"
                                        v-model="form.min_supply_gap_seconds"
                                        id="min_supply_gap_seconds">
                                    </b-form-input>
                                </b-input-group>
                            </b-row>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-container>
            </b-modal>
            <!-- Filtro input -->
            <b-row>
                <div style="float:right;">                    
                <b-form inline class="m-3" @submit.prevent="">
                    <label class="mr-2" for="filter" ><h4>Filtrar:</h4></label>
                    <b-input-group>
                        <b-form-input
                        id="filter"
                        v-model="filter_input"
                        placeholder="Buscar..."
                        >
                        </b-form-input>
                        <b-input-group-append>
                            <b-button size="sm" :disabled="!filter_input" @click="filter_input = ''">Borrar Filtro<b-icon-trash></b-icon-trash></b-button>
                        </b-input-group-append>
                        <b-input-group-append>
                            <b-button size="sm" variant="outline-success" @click="toggleDeleted()">{{btnText}}  <b-icon-search></b-icon-search></b-button>
                        </b-input-group-append>
                    </b-input-group>
                    
                </b-form>
                </div>
            </b-row>
            <!-- Tabla -->
            <b-table
            :fields="fields"
            :items="tanks"
            :responsive="true"
            :stacked="isMobile"
            show-empty
            empty-text="Sin resultados."
            :busy="busy"
            :filter="filter_input"
            >
            <template #table-busy>
                <b-skeleton-table
                animation="throb"
                :rows="5"
                :columns="4"
                :table-props="{ bordered: true, striped: true }"
                >
                </b-skeleton-table>

            </template>
            <template  #cell(enable_supply)="data">
                    <b-col 
                    v-show="statusTank(data.value)" 
                    lg="4" 
                    class="pb-2">
                        <b-button size="sm" @click="enabledDisabledTank(data.item.id_tanque, data.value)" :disabled="isReadOnly">
                            Deshabilitar<b-icon v-if="spinner" icon="arrow-counterclockwise" animation="spin-reverse"></b-icon>
                        </b-button>
                    </b-col>
                    <b-col 
                    v-show="statusTank(!data.value)" 
                    lg="4" 
                    class="pb-2">
                        <b-button variant="info" size="sm" @click="enabledDisabledTank(data.item.id_tanque, data.value)" :disabled="isReadOnly">
                            Habilitar
                            <b-icon
                            v-if="spinner" 
                            icon="arrow-counterclockwise" animation="spin-reverse"></b-icon>
                        </b-button>
                    </b-col>
            </template>
            <template #cell(update_params)="data">
                <div v-if="!data.item.deleted_at">
                    <b-button size="sm" class="ml-4" align-self="end" 
                    v-b-modal.edit-parameters @click="fillForm(data.item)">
                        <b-icon-pencil-fill  v-if="tienePermisoSystemUpdate"></b-icon-pencil-fill>
                        <b-icon-eye-fill  v-else></b-icon-eye-fill>
                    </b-button>
                    <b-button v-if="tienePermisoSystemDelete" size="sm" variant="danger" class="ml-4" align-self="end" @click="deleteTank(data.item)" >
                        <b-icon-trash></b-icon-trash>
                    </b-button>
                </div>
                <b-button v-else v-show="tienePermisoSystemDelete" size="sm" variant="success" class="ml-4" align-self="end" @click="restoreTank(data.item)" >
                    Restaurar
                </b-button>
            </template>

            </b-table>
    </b-container>
</template>
<script>
import axios from 'axios';
axios.defaults.withCredentials = true;
export default {
    name : 'TankList',
    props: {
        token: {
            type: String,
            required: true
        },
        endpoint_api: {
            type: String,
            required: true
        },
        isMobile: Boolean,
        permissions: Array
    },
    data() {
        return {
            options:[
                { value: 'default', text: 'default' },
                { value: 'i-u', text: 'i-u' },
                { value: 'from_transactions', text: 'from_transactions' },
            ],
            tanks: [],
            mensaje(msg,variant = null, title = null){
            this.$bvToast.toast(msg, {
                title: title != null ? title : null,
                variant: variant,
                solid: true
            })
            },
            filter_input:'',
            filters:[],
            spinner:false,
            busy: true,
            config: {},
            form:{
                id_tanque:'',
                analyze_from:'',
                temp_ref:'',
                flow_bound:'',
                flow_window_minutes:'',
                min_time_supply:'',
                min_start_point_time:'',
                threshold_supply_tank:'',
                threshold_flow_tank:'',
                min_threshold:'',
                max_threshold:'',
                analysis_method:''
            },
            show_deleted: false,
            fields: [
                {key:'id_tanque', label:"ID tanque", sortable: true},
                {key:'tanque', label:"Tanque", sortable: true},
                {key:'id_equipo', label:"ID equipo", sortable: true},
                {key:'site_name', label:"Sitio", sortable: true},
                {key:'product.name', label:"Producto", sortable: true},
                {key:'enable_supply', label:"Habilitar/Deshabilitar", sortable: true},
                {key:'update_params', label:"Acciones", sortable: true}  
            ]
        }
    },
    mounted: function () {
        this.getTanks();
    },
    methods:{
            getTanks() {
                let config = {
                    headers: {
                        Authorization: this.token
                    }
                };

                if (this.show_deleted) {
                    config.params = { show_deleted: 1 }
                }
                axios
                    .get(this.endpoint_api + '/tanks', config)
                    .then(response => {
                        this.busy = false;
                        this.tanks = response.data.data;
                        this.spinner = false;
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },
            enabledDisabledTank(id_tanque, enabled) {
                var accion;
                var titulo;
                this.spinner = true;

                let config = {
                        headers: {
                            Authorization: this.token
                        }
                };
                axios
                    .put(this.endpoint_api + '/enabled_tank/' + id_tanque +'/'+ enabled ,{}, config)
                    .then(() => {
                        if(enabled == false){
                            titulo = 'Habilitado'
                            accion = 'Se habilito el tanque Nº: '
                        } else if (enabled == true) {
                            titulo = 'Deshabilitado'
                            accion = 'Se deshabilito el tanque Nº: '
                        }
                        this.mensaje(accion + id_tanque, 'success', titulo);
                        this.getTanks();
                        this.spinner = false;

                    })
                    .catch(error => {
                        console.log(error);
                    })
            },
            fillForm(item) {
                this.filters =  item.filters,
                this.form = {
                    analyze_from: new Date(item.analyze_from),
                    id_tanque:item.id_tanque,
                    temp_ref:item.parameters.temp_ref,
                    flow_bound:item.parameters.flow_bound,
                    flow_window_minutes:item.parameters.flow_window_minutes,
                    min_time_supply:item.parameters.min_time_supply,
                    min_start_point_time:item.parameters.min_start_point_time,
                    threshold_supply_tank:item.parameters.threshold_supply_tank,
                    threshold_flow_tank:item.parameters.threshold_flow_tank,
                    min_threshold:item.parameters.min_threshold,
                    max_threshold:item.parameters.max_threshold,
                    analysis_method:item.parameters.analysis_method,
                    group_microsupplies: item.parameters.group_microsupplies || false,
                    min_supply_gap_seconds: item.parameters.min_supply_gap_seconds || 1800
                    
                }
            },
            clearForm() {
                this.filters =  [],
                this.form = {
                    analyze_from:'',
                    id_tanque:'',
                    temp_ref:'',
                    flow_bound:'',
                    flow_window_minutes:'',
                    min_time_supply:'',
                    min_start_point_time:'',
                    threshold_supply_tank:'',
                    threshold_flow_tank:'',
                    min_threshold:'',
                    max_threshold:'',
                    analysis_method:'',
                    group_microsupplies: false,
                    min_supply_gap_seconds: 1800
                }
            },
            updateParams(){
                let config = {
                    headers: {
                        Authorization: this.token
                    }
                };
                
                let body = {
                    analyze_from: this.form.analyze_from.toISOString(),
                    parameters: {
                        temp_ref: parseInt(this.form.temp_ref),
                        flow_bound:parseInt(this.form.flow_bound),
                        flow_window_minutes:parseInt(this.form.flow_window_minutes),
                        min_time_supply:this.form.min_time_supply,
                        min_start_point_time:this.form.min_start_point_time,
                        threshold_supply_tank:parseFloat(this.form.threshold_supply_tank),
                        threshold_flow_tank:parseFloat(this.form.threshold_flow_tank),
                        min_threshold:parseInt(this.form.min_threshold),
                        max_threshold:parseInt(this.form.max_threshold),
                        analysis_method: this.form.analysis_method,
                        group_microsupplies: this.form.group_microsupplies || false,
                        min_supply_gap_seconds: parseInt(this.form.min_supply_gap_seconds || 1800)
                    }
                }

                axios.put(process.env.VUE_APP_ENDPOINT_API + '/tanks/parameters/'+ this.form.id_tanque, body, config)
                .then(()=>{
                    this.mensaje(`Se actualizaron los parametros del tanque N°: ${this.form.id_tanque}`, 'success', 'Parametros Actualizados');
                    this.getTanks()
                    this.clearForm()
                })
                .catch((error)=>{
                    this.mensaje(error, 'danger', `No se pudo actualizar el tanque N°: ${this.form.id_tanque}`);
                    console.log(error)
                })

            },
            statusTank(data){
                if(data === true){
                    return true;
                } else {
                    return false;
                }
            },
            deleteTank(tank) {
                let optionsModal = {
                    title: 'Eliminar Tanque ' + tank.id_tanque,
                    size: 'md',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    headerClass: 'p-2 border-bottom-0',
                    footerClass: 'p-2 border-top-0',
                    cancelTitle: 'Cancelar',
                    okTitle: 'Eliminar',
                    hideHeaderClose: false,
                    centered: true
                };
                this.$bvModal.msgBoxConfirm(`¿ Desea eliminar el tanque N° ${tank.id_tanque} ?`, optionsModal)
                    .then((value) => {
                        if (value) {
                            let uri = `${process.env.VUE_APP_ENDPOINT_API}/tanks/${tank.id_tanque}`
                            axios.delete(uri,this.config)
                                .then(() => {
                                    this.mensaje(`El tanque N° ${tank.id_tanque} fue eliminado`, 'success', 'Tanque eliminado con exito');
                                    this.getTanks();
                                })
                                .catch(error => {
                                    if (error.response) {
                                        this.error = error.response.data.msg;  
                                        if (error.response.status == 401){
                                            this.text = "Para ver los datos debe reiniciar sesión en la plataforma."
                                        }
                                    } else {
                                        this.otherErr = true;
                                        this.textErr = error;
                                    }
                                    this.mensaje(this.error,'danger',`Hubo un problema eliminado el registro ${tank.id_tanque}`);
                                });
                        }
                    }).catch(e => console.log(e));

                return;
            },
            toggleDeleted() {
                this.show_deleted = !this.show_deleted;
                this.getTanks();
            },
            restoreTank(tank) {
                let optionsModal = {
                    title: 'Restaurar Tanque ' + tank.id_tanque,
                    size: 'md',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    headerClass: 'p-2 border-bottom-0',
                    footerClass: 'p-2 border-top-0',
                    cancelTitle: 'Cancelar',
                    okTitle: 'Restaurar',
                    hideHeaderClose: false,
                    centered: true
                };
                this.$bvModal.msgBoxConfirm(`¿ Desea restaurar el tanque N° ${tank.id_tanque} ?`, optionsModal)
                    .then((value) => {
                        if (value) {
                            let uri = `${process.env.VUE_APP_ENDPOINT_API}/tanks/restore/${tank.id_tanque}`;
                            let data = {
                                restore: true
                            }
                            axios.put(uri,data,this.config)
                                .then(() => {
                                    this.mensaje(`El tanque N° ${tank.id_tanque} fue restaurado`, 'success', 'Tanque restaurado con exito');
                                    this.getTanks();
                                })
                                .catch(error => {
                                    if (error.response) {
                                        this.error = error.response.data.msg;  
                                        if (error.response.status == 401){
                                            this.text = "Para ver los datos debe reiniciar sesión en la plataforma."
                                        }
                                    } else {
                                        this.otherErr = true;
                                        this.textErr = error;
                                    }
                                    this.mensaje(this.error,'danger',`Hubo un problema restaurando el registro ${tank.id_tanque}`);
                                });
                        }
                    }).catch(e => console.log(e));

                return;
             
            }
    },
    computed: {
        isChecked() {
            return this.form.group_microsupplies || false;
        },
        btnText() {
            return this.show_deleted ? 'Ver Activos' : 'Ver eliminados'
        },
        tienePermisoSystemCreate() {
            return this._.find(this.permissions, {objeto: 'SYSTEM', tipo:'CREATE'}) == undefined ? false : true;
        },
        tienePermisoSystemRead() {
            return this._.find(this.permissions, {objeto: 'SYSTEM', tipo:'READ'}) == undefined ? false : true;
        },
        tienePermisoSystemUpdate() {
            return this._.find(this.permissions, {objeto: 'SYSTEM', tipo:'UPDATE'}) == undefined ? false : true;
        },
        tienePermisoSystemDelete() {
            return this._.find(this.permissions, {objeto: 'SYSTEM', tipo:'DELETE'}) == undefined ? false : true;
        },
        isReadOnly() {
            return !this.tienePermisoSystemUpdate;
        }
    }
}            

</script>