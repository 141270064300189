<template>
    <b-container :fluid="isMobile ? false: true" class="ibox" style="background-color:white;">
      <b-modal body-class="p-2 modal-height-sm" id="modal-vincular" title="Vincular Abastecimiento" size="lg" centered @close="resetBindModal" @hide="resetBindModal">
        <b-tabs pills :small="true" card fill :vertical="false" v-model="tabIndex" active-nav-item-class="font-weight-bold text-uppercase text-white">
          <b-tab title="Seleccione un remito" >
            <b-table :items="remitos" :fields="fields_bindable_remitos" show-empty sticky-header="240px" responsive striped
              :empty-text="emptyMsg" selectable select-mode="single" selected-variant="success" empty-filtered-text="No se encontraron coincidencias."
              @row-selected="onRowSelected" caption-top :busy="bindable_busy" :filter="filter_bindable">
              <template #cell(fecha_hora)="data">
                {{ new Date(data.value).toLocaleString('es-ES', dateFormatOptions) }}
              </template>

              <template #table-caption>                
                <div class="d-flex justify-content-between">
                  <h3> Seleccione un remito:</h3>
                  <b-col cols="12" :lg="6" :sm="6" style="border:1px;">
                    <b-input-group size="sm" class="mb-1">
                        <b-form-input
                        id="filter-date"
                        v-model="bindable_date"
                        type="date"
                        @change="updateBindableList"
                        ></b-form-input>
                    </b-input-group>
                    <b-input-group size="sm">
                        <b-form-input
                        id="filter-input"
                        v-model="filter_bindable"
                        type="search"
                        placeholder="Buscar..."
                        ></b-form-input>
                    </b-input-group>
                  </b-col>
                </div>
              </template>      
            </b-table>
            <div class="text-center" v-if="remitos.length != 0">
              <small class="text-muted text-small">Se muestran remitos fechados en +-5 días de la fecha seleccionada. </small>
            </div>

            <p v-if="errorSeleccionarRemito" style="color:red">
              Debe seleccionar un remito
            </p>
          </b-tab>

          <b-tab title="Seleccione un item" :disabled="itemsOfSelected.length == 0 ? true : false">
            <b-table :items="itemsOfSelected" :fields="fields_items" show-empty sticky-header="240px" responsive striped
              :empty-text="emptyMsgItem" selectable select-mode="single" selected-variant="success"
              @row-selected="setItem" caption-top :busy="bindable_busy">
              <template #table-caption>                
                <div class="d-flex justify-content-between">
                  <h3> Seleccione un item:</h3>
                </div>
              </template>      
            </b-table>
          </b-tab>
        </b-tabs>

        <template #modal-footer class="text-center">
          <b-button-group class="mt-2">
            <b-button variant="outline-primary" @click="$bvModal.hide('modal-vincular')">Cancelar</b-button>
            <b-button v-if="tabIndex > 0" variant="outline-primary" @click="tabIndex--">Anterior</b-button>
            <b-button v-if="tabIndex == 0" @click="tabIndex++" variant="outline-primary" :disabled="disableNextPage">Siguiente</b-button>
            <b-button v-if="tabIndex != 0" variant="success" :disabled="disableNextPage" @click="vincularRemito">Vincular</b-button>
          </b-button-group>
        </template>
      </b-modal>

      <div class="ibox-title">
        <h5>Abastecimientos ({{ count_supplies }})</h5>
        <div class="ibox-tools"> 
          <b-button 
            v-if="tienePermisoManualSupplyCreate"
            class="m-t-n-xs mr-1"
            variant="success"
            size="sm"
            @click="crearRemito()"
            type="button">
            <span v-if="!isMobile">&nbsp;&nbsp; Cargar Remito</span>
            <b-icon-plus v-else variant="light"></b-icon-plus>
          </b-button>

          <b-button 
            v-if="groupCondition.length != 0"
            class="m-t-n-xs"
            variant="default"
            size="sm" 
            @click="toggleAccordion()"
            type="button">
            <b-icon-chevron-double-down v-if="showAccordion" scale="0.75" variant="dark" />
            <b-icon-chevron-double-up v-else scale="0.75" variant="dark"/>
          </b-button>

          <b-button 
            class="m-t-n-xs mr-1"
            variant="default"
            size="sm"
            @click="toggleFilters()"
            type="button">
            <b-icon-chevron-down v-if="showFilters" scale="0.80" variant="dark" />
            <b-icon-chevron-up v-else scale="0.80" variant="dark" />
            <span class="hidden-xs m-l-xs">&nbsp;&nbsp; Filtros</span>
          </b-button>

          <b-dropdown right no-caret
            size="sm"
            variant="info"
            class="m-t-n-xs"
            id="dropdown-buttons">
            <template v-slot:button-content>
              {{ isMobile ? '' : 'Exportar'}} <b-icon icon="download" />
            </template>
            <b-dropdown-item-button>
              <download-excel
                class     = ""
                :data="filtered == '' ? sinDatos : formatted"
                :fields   = "exportHeader"
                worksheet = "Abastecimientos"
                :name     = "name_xls">
                <b-icon icon="file-earmark-excel-fill" />
                Exportar en xls
              </download-excel>
            </b-dropdown-item-button>

            <b-dropdown-item-button>
              <download-excel
                class=""
                :data="filtered == '' ? sinDatos : formatted"
                :fields="exportHeader"
                worksheet="Abastecimientos"
                type="csv"
                :escapeCsv="false"
                :name="name_csv">
                <b-icon icon="file-earmark-text-fill"/>
                Exportar en csv
              </download-excel>
            </b-dropdown-item-button>
          </b-dropdown>  
        </div>
      </div>
      <b-modal id="modal-delete" title="Eliminar Abastecimiento"
        @ok="deleteSupply"
        @cancel="resetModal"
        :ok-disabled="this.statusOk ? true : false">
          <p>Seleccione la razón por la cual desea eliminar el abastecimiento detectado:</p>
          <b-form-select v-model="selected" :options="options_baja"></b-form-select>
          <p v-if="statusSelected" style="color:red;">Debe seleccionar un motivo</p>
        </b-modal>
      <div v-if="showFilters" class="ibox-content">
        <ValidationObserver v-slot="{ errors }">

          <b-row align-v="center">
            <!-- Filtros -->
            <b-col>
              <b-form-input type="text"
                v-model="filterSearch"
                class="form-control-custom"
                placeholder="Filtrar por centro operativo o producto...">
              </b-form-input>
            </b-col>
            <b-col :cols="isMobile ? '12' : ''">
              <ValidationProvider name="start" rules="notAfterNow" v-slot="{ errors }">
                <b-form-datepicker 
                  v-model="filterStart" 
                  locale="es"
                  id="datepicker_initial"
                  class="form-datepicker-custom"
                  :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                  :value-as-date="true"
                  :min="new Date(new Date(filterEnd).setDate(new Date().getDate()-180))"
                  :max="filterEnd"
                >
                </b-form-datepicker>
                <b-tooltip v-if="errors.length" show target="datepicker_initial" top>
                  {{ errors[0] }}
                </b-tooltip>
              </ValidationProvider>
            </b-col>
            <b-col :cols="isMobile ? '12' : ''">
              <ValidationProvider name="end" rules="notAfterNow|moreThanStart:@start" v-slot="{ errors }">
                <b-form-datepicker 
                  v-model="filterEnd" 
                  locale="es"
                  id="datepicker_final"
                  class="form-datepicker-custom"
                  :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                  :value-as-date="true"
                  :min="filterStart"
                  :max="new Date()">
                </b-form-datepicker>
                <b-tooltip v-if="errors.length" show target="datepicker_final" top>
                  {{ errors[0] }}
                </b-tooltip>
              </ValidationProvider>
            </b-col>
            <div v-show="!isMobile" class="">
              <b-icon-question-circle-fill role="button" v-show="!isMobile" v-b-tooltip.hover id="window-explanation" title="Tooltip directive content"></b-icon-question-circle-fill>
            </div>
            <b-tooltip target="window-explanation" triggers="hover" placement="bottomleft">
              El filtro funciona en formato ventana de 180 días, ajustada por la fecha final actualmente seleccionada.
            </b-tooltip>

            <!-- Agrupadores -->
            <b-col v-if="!isMobile">
              <b-button-group>
                <b-form-checkbox-group
                  size="sm"
                  id="btn-checkboxs-1"
                  v-model="groupCondition"
                  :options="grouper"
                  buttons
                  name="checkboxs-btn-default"
                ></b-form-checkbox-group>
              </b-button-group>
            </b-col>
            <b-col v-else cols="12">
              <b-row align-h="center">
                <b-button-group>
                  <b-form-checkbox-group
                    size="sm"
                    id="btn-checkboxs-1"
                    v-model="groupCondition"
                    :options="grouper"
                    buttons
                    name="checkboxs-btn-default"
                  ></b-form-checkbox-group>
                </b-button-group>
              </b-row>
            </b-col>
          </b-row>
        </ValidationObserver>
      </div>

      <div v-if="groupCondition.length != 0" :class="isMobile?'mb-5':'mb-3'">
        <div class="ibox-content no-padding" 
          v-for="(groupedList, groupKey) in grouped" 
          :key="groupKey">
          <b-row>
            <b-col cols="12" lg="12"
              class="ibox-title mediumgray-bg border-separator"
              @click="toggleItem(groupKey)">
              <h5>  
                <b-icon-chevron-down v-if="show[groupKey]" scale="0.80" variant="dark" />
                <b-icon-chevron-up v-else scale="0.80" variant="dark" />                
                <span> {{ groupKey }}</span>

              </h5>
              <!-- TODO: crear componente de totalizador <total /> -->
              <div class="ibox-tools mb-2">
                <span> Total (a 15ºC): {{ total(groupedList) | round(0) | format_num("de") }} </span>
              </div>
            </b-col>
          </b-row>
          <!-- TODO: crear componente de la tabla para no repetir (<data />) -->
          <!-- tabla agrupada -->
          <b-table
            bordered
            hover
            sort-by="fecha_hora_stop"
            :sort-desc="true"
            v-if="show[groupKey]"
            :fields="groupedHeader"
            :items="groupedList"
            group-by="grouped"
            class="no-margins first-last font-small"
            responsive
            item-key="_id"
            loading-text="Cargando, por favor espere...">
            <template v-slot:cell(fecha_hora_start)="{ item }">
              <span>{{ new Date(item.fecha_hora_start).toLocaleString('es-ES', dateFormatOptions) }}</span>
            </template>>
            <template v-slot:cell(fecha_hora_stop)="{ item }">
              <span>{{ new Date(item.fecha_hora_stop).toLocaleString('es-ES', dateFormatOptions) }}</span>
            </template>
            <template v-slot:cell(temp_comp)="{ item }">
              <span>{{ item.temp_comp | round(1) | format_num("es") }} ºC</span>
            </template>
            <template v-slot:cell(cantidad)="{ item }">
              <p class="text-right no-margins">{{ item.cantidad | round(1) | format_num("de") }} lts.</p>
            </template>
            <template v-slot:cell(cantidad_comp)="{ item }">
              <p class="text-right no-margins">{{ item.cantidad_comp | round(1) | format_num("de") }} lts.</p>
            </template>
            <template v-slot:cell(id_manual_supply)="{ item }">
              <p v-if="remitoValue(item) == ''" class="text-center no-margins">
                <b-button  v-b-modal.modal-vincular variant="outline-warning" 
                size="sm" @click="modalVincular(item)">
                  Vincular
                </b-button>
              </p>
              <p v-else class="text-center no-margins"><b-icon icon="clipboard-check"></b-icon> {{remitoValue(item)}}</p>
            </template>
            <template v-slot:cell(eliminar)="{ item }">
            <b-button
            v-b-modal="'modal-delete'"
            @cancel="resetModal"
            @click="abastecimientoValue(item)"
            size="sm"
            variant="danger">
              <b-icon icon="trash"></b-icon>
            </b-button>

          </template>
          </b-table>
          <b-row v-if="show[groupKey]" class="p-3">
            <b-col class="mr-auto"></b-col>
            <b-col cols="auto">
              <strong>Total (a 15ºC): {{ total(groupedList) | round(1) | format_num("de") }} lts.</strong>
            </b-col>
          </b-row>
        
        </div>
      </div>

      <div v-else class="ibox-content no-padding">
        <!-- tabla simple -->
        <b-table
          v-if="!busy"
          bordered
          hover
          responsive
          :sticky-header="tableHeight"
          sort-by="fecha_hora_stop"
          :sort-desc="true"
          :fields="groupedHeader"
          :items="filtered"
          group-by="grouped"
          class="no-margins first-last font-small"
          item-key="_id"
          loading-text="Cargando, por favor espere...">
          <template v-slot:cell(fecha_hora_start)="{ item }">
            <span>{{ new Date(item.fecha_hora_start).toLocaleString('es-ES', dateFormatOptions) }}</span>
          </template>>
          <template v-slot:cell(fecha_hora_stop)="{ item }">
            <span>{{ new Date(item.fecha_hora_stop).toLocaleString('es-ES', dateFormatOptions) }}</span>
          </template>
          <template v-slot:cell(temp_comp)="{ item }">
            <span>{{ item.temp_comp | round(1) | format_num("es") }} ºC</span>
          </template>
          <template v-slot:cell(cantidad)="{ item }">
            <!-- Formato de número en 'de' (Alemán) debido a que el estándar para 'es' no incluye el separador en
            números menores a 10000.
            https://stackoverflow.com/questions/57628055/tolocalestring-not-working-on-numbers-less-than-10000-in-all-browsers -->
            <p class="text-right no-margins">{{ item.cantidad | round(1) | format_num("de") }} lts.</p>
          </template>
          <template v-slot:cell(cantidad_comp)="{ item }">
            <p class="text-right no-margins">{{ item.cantidad_comp | round(1) | format_num("de") }} lts.</p>
          </template>
          <template v-slot:cell(id_manual_supply)="{ item }">
            <p v-if="remitoValue(item) == ''" class="text-center no-margins">
              <b-button  v-b-modal.modal-vincular variant="outline-warning" 
              size="sm" @click="modalVincular(item)">
                Vincular
              </b-button>
            </p>
            <p v-else class="text-center no-margins">
              <!-- <b-icon icon="clipboard-check"></b-icon> {{remitoValue(item)}} -->
              <b-button size="sm" variant="outline-info" @click="verRemito(item.id_manual_supply)"> <b-icon icon="clipboard-check"></b-icon> {{remitoValue(item)}}</b-button>
            </p>
          </template>
          <template v-slot:cell(eliminar)="{ item }">
            <b-row align-h="end">
              <b-button
              v-b-modal="'modal-delete'"
              @click="abastecimientoValue(item)"
              size="sm"
              variant="danger">
                <b-icon icon="trash"></b-icon>
              </b-button>
            </b-row>
          </template>
        </b-table>
        <template>
        <div class="text-center m-5">
          <div class="spinner-border " role="status" v-show="busy"></div>
        </div>
        </template>
        <b-row :class="isMobile ? 'pb-5' : 'p-3' ">
          <b-col class="mr-auto"></b-col>
          <b-col cols="auto">
            <strong>Total (a 15ºC): {{ total(filtered) | round(1) | format_num("de") }} lts.</strong>
          </b-col>
        </b-row>
      </div>
      <div  class="ibox-content" v-if="errors.length">
        <div style="text-align:center;" v-for="(error, idx) in errors" :key="idx">
          <b-icon style="width:90px; height: 90px;" icon="exclamation-triangle-fill" variant="danger"></b-icon> <p class="h4 mb-2">{{' ' + error}}</p>
        </div>
      </div>
      <div class="ibox-content no-margins"
        v-if="count_supplies == 0">
        Sin datos entre {{ new Date(filterStart).toLocaleString('es-ES', { year: 'numeric', month: '2-digit', day: '2-digit'}) }}
         y {{ new Date(filterEnd).toLocaleString('es-ES', { year: 'numeric', month: '2-digit', day: '2-digit'}) }}
      </div>
    </b-container>
</template>

<script>
import axios from 'axios';
axios.defaults.withCredentials = true;

var store = {
  state: {
    lastFilterStart: ''
  },
  setNewStartDate (newValue) {
    this.state.lastFilterStart = newValue
  },
};

export default {
  name: 'SupplyList',

  props: {
    token: {
      type: String,
      required: true
    },
    endpoint_api: {
      type: String,
      required: true
    },
    permissions: Array
  },

  data() { 
    return { 
      selected: null,
      item_abastecimiento_id: '',
      sinRemito: false,
      options_baja: [
        { value: 1, text: 'Vibraciones o movimientos en tanques móviles' },
        { value: 2, text: 'Cantidad muy baja' },
        { value: 3, text: 'Hubo cambio de sonda en el tanque' },
        { value: 4, text: 'Se retiró la sonda del tanque' },
        { value: 5, text: 'Se hicieron movimientos de prueba' },
        { value: 6, text: 'Se modificó la tabla de calibración' },
        { value: 7, text: 'Tanques sifonados' },
        { value: 8, text: 'Problemas de venteo' },
        { value: 9, text: 'Otro motivo' }
      ],
      disabledDates: {
        from: new Date(), //fecha minima posible
        to: new Date(new Date().setDate(new Date().getDate()-90)) //fecha maxima posible
      },
      disabledDates_max:{
        from:new Date(), //fecha minima posible
      },
      filterBackStart: (d => new Date(d.setDate(d.getDate()-8)))(new Date),
      filterBackEnd: new Date(),
      filterStart: (d => new Date(d.setDate(d.getDate()-7)))(new Date),
      filterEnd: new Date(), 
      filterSearch: '',
      window: {
      width: 0,
      height: 0
      },
      showFilters: true,
      show: [],
      showAccordion: false,
      loading: true,
      groupCondition: [],
      busy:false,
      lang: {
      },
      grouper : [
        { text: 'Sitio', value: 'site_name', state: false },
        { text: 'Tanque', value: 'tank_site_id',  state: false },
        { text: 'Producto', value: 'producto_name',  state: false },
      ],
      headers : [
        { label: 'Fecha y Hora inicio', key: 'fecha_hora_start', sortable: true},
        { label: 'Fecha y Hora fin', key: 'fecha_hora_stop', sortable: true },
        { label: 'Sitio', key: 'site_name', sortable: true },
        { label: 'Tanque', key: 'tank_site_id', sortable: true },
        { label: 'Producto', key: 'producto_name', sortable: true },
        { label: 'Temperatura', key: 'temp_comp', sortable: true },
        { label: 'Cantidad', key: 'cantidad', sortable: true },
        { label: 'Cantidad (a 15ºC)', key: 'cantidad_comp', sortable: true },
      ],
      errors: [],
      statusSelected : false,
      messages: [],
      supplies : [],
      tabIndex: 0,
      remitos: [],
      bindable_remitos: [],
      fields_bindable_remitos: [  
        {key:'fecha_hora', label:"Fecha", sortable: true},
        {key:'nro_remito', label:"Remito N°", sortable: true},
        {key:'proveedor', sortable: true},
        {key:'cuit', label:"CUIT", sortable: true}
      ],
      fields_items: [
        {key:'producto_name', label:"Producto", sortable: true},
        {key:'cantidad', label:"Cantidad (lts)", sortable: true},
        {key:'temp', label:"Temperatura (C°)", sortable: true},
      ],
      config: {},
      emptyMsg: "No se encontraron remitos con fecha en el rango del abastecimiento seleccionado.",
      emptyMsgItem: "No se cargaron items en el remito seleccionado. Modifique el remito primero.",
      selectedRow: [],
      selectedSupplyItem: [],
      currentSupply: {},
      errorSeleccionarRemito: false,
      dateFormatOptions: {
        year: 'numeric', 
        month: '2-digit', 
        day: '2-digit', 
        hour: '2-digit' , 
        minute: '2-digit'
      },
      bindable_date: '',
      bindable_busy: false,
      filter_bindable: null
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },

  filters: {
    format_num: function(num, lang) {
      try {
        return num.toLocaleString(lang);
      } catch {
        return num;
      }
    }
  },

  computed: {
    statusOk(){
      if(this.selected == null){
        return true;
      } else {
        return false;
      }
    },
    itemsOfSelected() {
      return this.selectedRow.length ? this.selectedRow[0].items : new Array();
    },
    exportHeader() {
      let headers = {}
      if (this.groupCondition.length != 0){
        this.headers.map((h)=>{
          if (!this.groupCondition.includes(h.value)){
            headers[h.label] = h.key;
          }
        })
      } else {
        this.headers.map((h)=>{
          headers[h.label] = h.key;
        })
      }
      return headers; 
    },
    groupedHeader() {
      if (this.groupCondition.length != 0){
        let headers = [];
        this.headers.map((h)=>{
          if (!this.groupCondition.includes(h.value)){
            headers.push(h);
          }
        })
        return headers;
      }
      return this.headers;
    },
    isMobile() {
      return this.window.width <= 768 ? true : false;
    },
    tableHeight() {
      let half = this.window.height / 2;
      return half < 300 ? '300px' : half+'px';
    },
    tienePermisoManualSupplyCreate() {
      let manual = this._.find(this.permissions, {objeto: 'MANUAL_SUPPLY', tipo:'CREATE'}) == undefined ? false : true;
      let manual_canal = this._.find(this.permissions, {objeto: 'MANUAL_SUPPLY_CANAL', tipo:'CREATE'}) == undefined ? false : true;
      return manual || manual_canal;
    },
    tienePermisoManualSupplyUpdate() {
      let manual = this._.find(this.permissions, {objeto: 'MANUAL_SUPPLY', tipo:'UPDATE'}) == undefined ? false : true;
      let manual_canal = this._.find(this.permissions, {objeto: 'MANUAL_SUPPLY_CANAL', tipo:'UPDATE'}) == undefined ? false : true;
      return manual || manual_canal;
    },
    tienePermisoSupplyDelete() {
      let sup = this._.find(this.permissions, {objeto: 'SUPPLY', tipo:'DELETE'}) == undefined ? false : true;
      let sup_canal = this._.find(this.permissions, {objeto: 'SUPPLY_CANAL', tipo:'DELETE'}) == undefined ? false : true;
      return sup || sup_canal;
    },
    grouped() {
      // FIXME
      if (this.groupCondition.length == 0) {
        return this.filtered;
      } else if (this.groupCondition.length == 1) {
        let supplies = this._.groupBy(this.filtered, this.groupCondition[0]);
        let sup = {};
        for (let key in supplies) {
          let key_name = key.toString();
          if (key_name.length == 1) { key_name = "Tanque " + key_name; }
          sup[key_name] = supplies[key];
        }
        return sup;
      } else if (this.groupCondition.length == 2) {
        let supplies = this._.groupBy(this.filtered, this.groupCondition[0]);
        for (let key in supplies) {
          supplies[key] = this._.groupBy(supplies[key], this.groupCondition[1]);
        }
        let sup = {};
        for (let key in supplies) {
          for (let subkey in supplies[key]) {
            let key_name = key.toString();
            let subkey_name = subkey.toString();
            if (key_name.length == 1) { key_name = "Tanque " + key_name; } 
            if (subkey_name.length == 1) { subkey_name = "Tanque " + subkey_name; } 
            sup[key_name + ' - ' + subkey_name] = supplies[key][subkey];
          }
        }
        return sup;
      } else if (this.groupCondition.length == 3) {
        let supplies = this._.groupBy(this.filtered, this.groupCondition[0]);
        for (let key in supplies) {
          supplies[key] = this._.groupBy(supplies[key], this.groupCondition[1]);
          for (let subkey in supplies[key]) {
            supplies[key][subkey] = this._.groupBy(supplies[key][subkey], this.groupCondition[2]);
          }
        }
        let sup = {};
        for (let key in supplies) {
          for (let subkey in supplies[key]) {
            for (let subsubkey in supplies[key][subkey]) {
              let key_name = key.toString();
              let subkey_name = subkey.toString();
              let subsubkey_name = subsubkey.toString();
              if (key_name.length == 1) { key_name = "Tanque " + key_name; } 
              if (subkey_name.length == 1) { subkey_name = "Tanque " + subkey_name; } 
              if (subsubkey_name.length == 1) { subsubkey_name = "Tanque " + subsubkey_name; } 
              sup[key_name + ' - ' + subkey_name + ' - ' + subsubkey_name] = supplies[key][subkey][subsubkey];
            }
          }
        }
        return sup;
      } else {
        return this.supplies;
      }
    },
    filtered() {
      return this.supplies
        .filter((supply) => {
          return this.filterSearch
            .split(" ")
            .map((k) => {
              return k.toLowerCase().replace(/[^\wáéíóúüñç\d]/, '')
            })
            .some((key) => {
              return supply.site_name.toLowerCase().includes(key)
                || supply.id_equipo.toString().includes(key)
                || supply.producto_name.toLowerCase().includes(key);
            })
        })
        .filter((supply) => {
          return new Date(supply.fecha_hora_stop) >= this.filterStart.setHours(0,0,0,0);
        })
        .filter((supply) => {
          return new Date(supply.fecha_hora_stop) <= this.filterEnd.setHours(23,59,59,59);
        }); 
    },
    count_supplies() {
      return this.filtered.length;
    },
    name_csv() {
      return 'abastecimientos-' 
        + this.printDate(this.filterStart) 
        + '-a-' 
        + this.printDate(this.filterEnd) 
        + '.csv';
    },
    name_xls() {
      return 'abastecimientos-' 
        + this.printDate(this.filterStart) 
        + '-a-' 
        + this.printDate(this.filterEnd)
        + '.xls';
    },
    formatted() {
      return this._.orderBy(this.filtered, ['fecha_hora_stop'], ['desc'])
        .map((row) => {
          let formatted = Object.assign({}, row);
          if (row.hasOwnProperty("temp_comp")) {
            formatted["temp_comp"] = this.round(row["temp_comp"]);
          }
          if (row.hasOwnProperty("cantidad")) {
            formatted["cantidad"] = this.round(row["cantidad"]);
          }
          if (row.hasOwnProperty("cantidad_comp")) {
            formatted["cantidad_comp"] = this.round(row["cantidad_comp"]);
          }
          if (row.hasOwnProperty("fecha_hora_start")) {
            formatted["fecha_hora_start"] = this.printDateTime(new Date(row["fecha_hora_start"]));
          }
          if (row.hasOwnProperty("fecha_hora_stop")) {
            formatted["fecha_hora_stop"] = this.printDateTime(new Date(row["fecha_hora_stop"]));
          }
          if (row.hasOwnProperty("id_manual_supply")) {
            if (row.id_manual_supply !== undefined && row.id_manual_supply !== null) {
              formatted["id_manual_supply"] = row["id_manual_supply"]["nro_remito"]
            } else {
              formatted["id_manual_supply"] = 'Sin asignar';
            }
          }
          return formatted
        });
    },
    sinDatos(){
      let datos = [
        { "_id": "", "analysis_method": "", "cantidad": "", "cantidad_comp": "", "coef_var_vol": "", "created_at": "", "fecha_hora_start": "", "fecha_hora_stop": "", "id_equipo": "", "id_tanque": "", "parameters": { "analysis_method": "", "flow_bound": "", "flow_window_minutes": "", "max_threshold": "", "min_start_point_time": "", "min_threshold": "", "min_time_supply": "", "temp_ref": "", "threshold_flow_tank": "", "threshold_supply_tank": "" }, "producto": "", "producto_name": "", "script_version": "", "site_name": "", "tank_site_id": "", "temp_comp": "", "temp_ref": "", "temp_start": "", "temp_stop": "", "volumen_start": "", "volumen_start_comp": "", "volumen_stop": "", "volumen_stop_comp": "", "id_manual_supply": "", "updatedAt": ""
        }]
      return datos
    },
    disableNextPage() {
      if (this.tabIndex === 0) {
        if (!this.selectedRow.length) return true;
        return false
      }
      if (this.tabIndex === 1) {
        if (!this.selectedSupplyItem.length) return true;
        return false
      }
      return true;
    },
  },

  mounted() {
    store.setNewStartDate(this.filterBackStart);
    this.getSupplies();
    if (this.tienePermisoManualSupplyUpdate) {
      this.getRemitosToBind();
    }
  },

  methods: {
    resetModal(){
      this.selected = null;
      this.item_abastecimiento_id = '';
      return;
    },
    setItem(item) {
      this.selectedSupplyItem = item;
    },
    deleteSupply(){
      var item = this.item_abastecimiento_id;
      this.config = {
        headers: {
          Authorization: this.token
        }
      }          
      if(this.selected != null && item != ''){
        let uri = `${process.env.VUE_APP_ENDPOINT_API}/${item}/delete/${this.selected}`;
        axios.delete(uri, this.config)
        .then(() => {
          this.getSupplies();
          this.mensaje('El abastecimiento fue eliminado', 'success', 'Abastecimiento Eliminado');
          this.resetModal();
        })
        .catch(error => {
          if (error.response) {
            this.mensaje(error.response.data.msg, 'danger', 'Hubo un problema');
          } else {
            error = error != undefined ? error : '';
            this.mensaje(error, 'danger', 'Hubo un problema');
          }
        })
      } else {
        this.statusSelected = true;
      }
      return;
    },
    onRowSelected(items) {
      this.selectedRow = items;
    },
    verRemito(item) {
      this.$router.push({name: 'admin-modal', params: {id_manual_supply: item._id}});
    },
    crearRemito() {
      this.$router.push({name: 'admin', query: { modal: true }});
    },
    mensaje(msg,variant = null, title = null){
      this.$bvToast.toast(msg, {
        title: title != null ? title : null,
        variant: variant,
        solid: true
      })
    },
    vincularRemito() {
      if (this.selectedRow.length == 0 || this.selectedSupplyItem.length == 0) {
        this.errorSeleccionarRemito = true;
        return;
      }

      let id = this.selectedRow[0]._id;
      let item_id = this.selectedSupplyItem[0]._id;
        
      this.config = {
        headers: {
          Authorization: this.token
        }
      };

      let body = {
        id_manual_supply: id,
        id_manual_supply_item: item_id
      }

      let uri = process.env.VUE_APP_ENDPOINT_API+`/${this.currentSupply._id}`;
      axios.put(uri, body, this.config)
      .then( response => {
        response.msg = 'OK';
        let index = this._.findIndex(this.supplies, { _id: this.currentSupply._id});
        this.$set(this.supplies[index], 'id_manual_supply', this.selectedRow[0]);
        this.resetBindModal();
        this.$bvModal.hide('modal-vincular');
        this.mensaje('Se vinculó correctamente el abastecimiento con el remito seleccionado', 
        'success', 'Abastecimiento Vinculado');     
      })
      .catch(error => {
        console.log(error)
        this.resetBindModal();
        this.$bvModal.hide('modal-vincular');
        this.mensaje(error,'danger', 'Hubo un error');
      })
    },
    resetBindModal() {
      this.selectedRow = [];
      this.currentSupply = {};
      this.selectedSupplyItem = [];
      this.tabIndex = 0;
    },
    getRemitosToBind() {
      this.config = {
        headers: {
          Authorization: this.token
        }
      };
      let uri = process.env.VUE_APP_ENDPOINT_API+'/manual';
      axios
      .get(uri, this.config)
      .then( (response) => {
        this.bindable_remitos = response.data.data;
        if (this.bindable_remitos.length == 0) {
          this.msgBindedSupplies = 'No se encontraron remitos';
        }
      })
      .catch( error => {
        console.log(error);
      })
    },
    modalVincular(item) {
      try {
        this.currentSupply = item;
        var fecha = item.fecha_hora_start;
        let from,to;
        from = new Date(fecha)
        from = from.setDate(from.getDate()-5);
        to = new Date(fecha)
        to = to.setDate(to.getDate()+5);
        this.remitos = this.bindable_remitos.map( r => {
          let fechaAComparar = new Date(r.fecha_hora).getTime();
          if((fechaAComparar <= to && fechaAComparar >= from)) {
            return r;
          }
          return false;
        });
        this.remitos = this._.filter(this.remitos, 'fecha_hora');

      } catch (error) {
        console.log(error);
        this.remitos = this.bindable_remitos;
        this.currentSupply = item;
      }
    },
    updateBindableList() {
      this.bindable_busy = true;
      let from,to;
      from = new Date(this.bindable_date);
      from = from.setDate(from.getDate()-5);
      to = new Date(this.bindable_date);
      to = to.setDate(to.getDate()+5);
      this.remitos = this.bindable_remitos.map( r => {
        let fechaAComparar = new Date(r.fecha_hora).getTime();
        if((fechaAComparar <= to && fechaAComparar >= from)) {
          return r;
        }
        return false;
      });
      this.remitos = this._.filter(this.remitos, 'fecha_hora');
      this.bindable_busy = false;
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    getSupplies() {
      this.supplies = [];
      this.busy = true;
      let config = {
        headers: {
          Authorization: this.token
        },
        params: {
          desde: this.filterBackStart,
          hasta: this.filterBackEnd
        }
      };

      axios
        .get(this.endpoint_api, config)
        .then(response => { 
          this.busy = false;
          this.supplies = response.data.data.concat(this.supplies);
          this.setActions();
        })
        .catch(e => {
          this.busy = false;
          console.log(e);
          if(e.response){
          this.errors.push(e.response.data.msg);
          }
          else {
            this.errors.push(e);
          }
        });

      this.toggleAccordion();
    },
    toggleFilters() {
      this.showFilters = !this.showFilters;
    },
    remitoValue(item){
      
      if (item.id_manual_supply === undefined || item.id_manual_supply === null) {
        return '';
      } else {
        return item.id_manual_supply.nro_remito;
      }
    },
    setActions(){
      if (this.tienePermisoManualSupplyUpdate) {
        this.headers.push({ label: 'N° Remito', key: 'id_manual_supply', sortable: true });
      }
      if (this.tienePermisoSupplyDelete) {
        this.headers.push({ label: 'Eliminar', key: 'eliminar', sortable: true });
      }
    },
    abastecimientoValue(item){
      
      if (item._id === null) {
        return ''
      } else {
        this.item_abastecimiento_id = item._id
        return item._id;
      }
    },
    toggleAccordion() {
      for (var key in this.grouped) {
        this.show[key] = this.showAccordion;
      }
      this.showAccordion = !this.showAccordion;
    },
    toggleItem(item) {
      if (this.show[item] == undefined) { this.$set(this.show, item, false); }
      this.show[item] = !this.show[item];
    },
    total(lista) {
      return lista.reduce(function(total, item) { return total + item.cantidad_comp }, 0);
    },
    printDate(date) {
      return ('0' + date.getDate()).slice(-2)
        + '-' + ('0' + (date.getMonth()+1)).slice(-2) 
        + '-' + date.getFullYear();
    },
    printDateTime(date) {
      return ('0' + date.getDate()).slice(-2)
        + '-' + ('0' + (date.getMonth()+1)).slice(-2) 
        + '-' + date.getFullYear()
        + ' ' + ('0' + date.getHours()).slice(-2)
        + ':' + ('0' + date.getMinutes()).slice(-2);
    },
    round(num) {        
      let parsed = Math.round((num + Number.EPSILON) * 10) * 0.1;
      return parsed.toFixed(2);
    }
  },

  watch: {
    filterStart: function (item) {
      if (item < this.filterBackStart) {
        this.filterBackStart = item; 
        this.filterBackEnd = this.filterEnd; 
        this.getSupplies();
        store.setNewStartDate(this.filterBackStart);
      } else {
        this.filterBackStart = this.filterStart;
        this.filterBackEnd = this.filterEnd;
        // this.getSupplies();
        store.setNewStartDate(this.filterBackStart);
      }
    },
    filterEnd: function (item) {
      if (item > this.filterBackEnd) {
        this.filterBackStart = this.filterStart; 
        this.filterBackEnd = item; 
        this.getSupplies();
        store.setNewStartDate(this.filterBackStart);
      }
    }
  }
};
</script>
