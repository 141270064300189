<template>
    <b-container :fluid="isMobile ? false : true" :class="isMobile ? '' : ''">
        <div class="text-center mt-5">
            <b-icon style="width:90px; height: 90px;" icon="exclamation-triangle-fill" variant="danger"></b-icon> <p class="h1 mb-2">{{title}}</p>
            <p style="font-size:20px">{{text}}</p>
            <b-link v-if="!admin_no_modo_empresa && this.$route.name != '403'" :href="defeated ? loginCore : undefined" @click="defeated ? undefined : $router.go(-1)">{{link}}</b-link>
        </div>
    </b-container>
</template>
<script>
export default {
    title() {
        return `${this.$route.name ? this.$route.name : 404} | Abastecimientos | `
    },
    props: {
        defeated: {
            type: Boolean,
            default: false
        },
        admin_no_modo_empresa: {
            type: Boolean,
            default: false
        },
        canal: Object,
        isMobile: Boolean,
        core_uri: String
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.setTitle(vm.canal.canal);         
        });
    },
    watch: {
        'canal.canal' (val) {
            this.setTitle(val);
        }
    },
    computed: {
        text() {
            return this.defeated && this.$route.name == '401' ? 'Para ver los datos debe reiniciar sesión en la plataforma.' : this.admin_no_modo_empresa || this.$route.name == '403' ? 'No puede ingresar sin usar el modo Empresa' : 'La página a la que intenta acceder no existe.';
        },
        title() {
            return this.defeated && this.$route.name == '401' ? 'Sesión Vencida' : this.admin_no_modo_empresa || this.$route.name == '403' ? 'ERROR 403' : 'ERROR 404';
        },
        link() {
            return this.defeated ? 'Login' : 'Volver'
        },
        loginCore() {
            return this.core_uri + '/login.mod.php';
        }

    }
}
</script>