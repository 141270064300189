<template>
  <div>
    <router-view name="sidebar" v-if="!maintenanceMode" :core_uri="core_uri" :canal="canal" :isAdmin="tienePermisoSystem" :permissions="this.permissions" :window="this.window" :isMobile="this.isMobile" :token="this.token" class="mr-auto" />
    <b-container :fluid="isMobile ? false : true" :class="isMobile ? 'mr-0 ml-0 pr-0 pl-0' : 'mr-0 pr-0'">
      <router-view name="title" :core_uri="this.core_uri" :isMobile="isMobile" />
    </b-container>
    <Modal/>
    <b-container :class="isMobile && !maintenanceMode ? 'm-0 p-0' : 'ml-4 mr-0'" :fluid="isMobile ? false : true">
      <div v-if="maintenanceMode" 
        class="text-center"
        style="
        margin-top:15%;
        display: flex;
        justify-content: center;
        align-items: center;">
        <div>
        <div class="m-4"><b-icon scale="4" icon="tools"></b-icon></div>
        <h2 v-if="maintenance_msg != ''">{{maintenance_msg}}</h2> 
        <h2 v-else>
          Estamos realizando tareas de mantenimiento para ofrecerle un mejor servicio.
        </h2>
        <h3>¡Disculpe las molestias ocasionadas!</h3>
        <i class="fa cin cin-logo mb-1" style="font-size:3em"></i>
        </div>
      </div>
      <router-view v-else :isAdmin="tienePermisoSystem" :isMenor="this.isMenor" :permissions="this.permissions" :core_uri="core_uri" :token="this.token" :endpoint_api="this.endpoint_api" :isMobile="this.isMobile" :key="$route.path">

      </router-view>
    </b-container>
    <div>
    <router-view name="footer" :core_uri="this.core_uri" :class="!isMobile? 'footer_not_mobile': ''" :window="this.window" :canal="canal"/>
    </div>
  </div>
</template>

<script>
import Modal from './components/Modal';
import { VUE_APP_CORE_URI, VUE_APP_MAINTENANCE_MODE, VUE_APP_MAINTENANCE_MSG } from './config';

export default {
  name: 'App',
  title: 'Abastecimientos | ',
  components: {
    Modal
  },
  data() {
    return {
      token: '',
      endpoint_api: process.env.VUE_APP_ENDPOINT_API,
      core_uri: VUE_APP_CORE_URI[VUE_APP_CORE_URI.length] == '/' 
        ? VUE_APP_CORE_URI.substring(0, VUE_APP_CORE_URI.length - 1) 
        : VUE_APP_CORE_URI,
      maintenanceMode: VUE_APP_MAINTENANCE_MODE == 0 ? false : true,
      maintenance_msg: VUE_APP_MAINTENANCE_MSG,
      window: {
        width: 0,
        height: 0
      },
      permissions: [],
      canal: {
        canal: '',
        id_canal: '',
        default_name: ''
      },
    };
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  computed: {
    isMenor() {
            return this.window.width <= 990 ? true : false;
    },
    isMobile() {
      return this.window.width <= 768 ? true : false;
    },
    tienePermisoSystem() {
      return this._.find(this.permissions, {objeto: 'SYSTEM', tipo:'UPDATE'}) == undefined ? false : true;
    }
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    }
  }
};
</script>
