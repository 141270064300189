import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'Campo requerido'
});

extend('notAfterNow', {
  validate(value) {
    return new Date(value).setHours(0,0,0,0) <= new Date();
  },
  message: 'Fecha no válida'
});

extend('moreThanStart', {
  params: ['target'],
  validate(value, { target }) {
    return new Date(value) >= new Date(target);
  },
  message: 'Fecha no válida'
})
