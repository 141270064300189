<template>
  <div>
    <sidebar-menu 
      :menu="!this._.isEmpty(menu) ? menu : errorMenu" 
      theme='white-theme'
      :showOneChild="true" 
      :collapsed="collapsed" 
      @toggle-collapse="onToggleCollapse"
      :widthCollapsed="window.width < 768 ? '0px' : '50px'"
      :class="window.width < 768 & collapsed ? 'ocultar' : ''"
      @item-click="onItemClick"
      :width="window.width < 768 && !collapsed ? window.width + 'px' : '250px'" >

      <div slot="header" v-if="isCollapsed & window.width > 768">
        <p class="h1 header-custom" style="font-size:4em" v-if="canal.canal == 'cintelink'">
          <b-button class="h1 mb-2" variant="outline">
            <i class="fa cin cin-logo" style="font-size:1.5em"></i>
          </b-button>
        </p>
        <div v-else class="pb-3 pt-3 ml-1 mr-1 text-center">
          <b-skeleton-img v-if="!loaded" variant="light"></b-skeleton-img>
          <b-img  style="max-height:60px" v-show="loaded && !failLoadImg" @error="failLoad" @load="hasLoaded" fluid :src="getSrcIcon" :alt="getAlt+' icono'" ></b-img>
          <b-avatar variant="primary" v-show="loaded && failLoadImg" :text="channelName"></b-avatar>
        </div>
      </div>
      <div v-else slot="header" class="py-4 container">
        <b-skeleton-img v-if="!loaded"></b-skeleton-img>
        <b-img v-show="loaded" fluid @load="hasLoaded" :src="getSrcImgNotCollapsed" :alt="getAlt+'no icono'"></b-img>
      </div>

      <b-icon icon='chevron-down' scale="0.70" shift-v="-2" slot="dropdown-icon"></b-icon>
      <b-icon icon="justify" slot="toggle-icon"></b-icon>
    </sidebar-menu>

    <div class="button-collapse-pos" v-if="window.width < 768">
      <b-button @click="toggle" variant="secondary">
          <b-icon icon="justify" ></b-icon>
      </b-button>
    </div>
  </div>
</template>

<script>
import { VUE_APP_COLORED_CHANNELS } from '../config';

export default {
  name: 'Sidebar',

  props: {
    core_uri: String,
    active: String,
    token: String,
    permissions: Array,
    canal: {
      canal: String,
      id_canal: String,
      default_name: String
    },
    endpoint_api: String,
    isAdmin: Boolean,
    window: {
      width: Number,
      height: Number
    },
    isMobile: Boolean
  },

  data() {
    return {
      errorMenu: [],
      menu: [],
      empresa: '',
      usuario: '',
      logout: '/login.mod.php?q=YWNjaW9uPWNsb3NlU2Vzc2lvbg%3D%3D',
      isCollapsed: true,
      collapsed: true,
      canales_color: VUE_APP_COLORED_CHANNELS,
      loaded: false,
      failLoadImg: false,
    }
  },

  mounted() {
    this.renderMenu();
  },
  computed: {
    year() {
      let year = new Date().getFullYear();
      return year;
    },
    configMenu() {
      return {
        href: '/config',
        title: 'Config. de Tanques',
        alias: ['/config', '/config?modal=true'],
        icon: {
          element: "b-icon-wrench",
          class: "p-1"
        }   
      }
    },
    remitoMenu() {
      return {
        href: '/administrar',
        title: 'Administración',
        alias: ['/administrar', '/administrar?modal=true'],
        icon: {
          element: "b-icon-gear-fill",
          class: "p-1"
        }   
      }
    },
    inicioMenu() {
      return {
        href: this.core_uri+'/inicio.mod.php',
        title: 'Ir al inicio',
        icon: {
          element: "b-icon-arrow-left-circle",
          class: "p-1"
        },
        external: true   
      }
    },
    logOutMenu() {
      return {
        href: this.core_uri+this.logout,
        title: 'Cerrar Sesión',
        icon: {
          element: "b-icon-person-fill",
          class: "p-1"
        },
        external: true   
      }
    },
    listaMenu(){
      return {
        href: '/',
        title: 'Reporte',
        icon: {
          element: "b-icon-bar-chart-line-fill",
          class: "p-1"
        }   
      }
    },
    infoMenu(){
      return {
        href: '#about-us',
        title: 'Información',
        icon: {
          element: "b-icon-info-circle-fill",
          class: "p-1"
        }   
      }
    },
    hasManuals() {
      let manual = this._.find(this.permissions, {objeto: 'MANUAL_SUPPLY', tipo:'READ'}) == undefined ? false : true;
      let manual_canal = this._.find(this.permissions, {objeto: 'MANUAL_SUPPLY_CANAL', tipo:'READ'}) == undefined ? false : true;
      return manual || manual_canal;
    },
    getSrcIcon() {
      if (this.canal.canal == '') { return ''; }
      if (!this.canales_color.includes(this.canal.canal)){
        return this.core_uri+'/control/img/'+this.canal.canal+'_iso.png';
      } else {
        return this.core_uri+'/control/img/'+this.canal.canal+'_iso_color.png';
      }
    },
    getSrcImgNotCollapsed() {
      if (this.canal.canal == '') { return ''; }
      if (this.canal.canal != 'cintelink'){
        return this.core_uri+'/control/img/'+this.canal.canal+'-color.png';        
      } else {
        return '/logo-color.png';
      }
    },
    getAlt() {
      if (this.canal.canal != 'cintelink'){
        return 'Logo '+this.canal.canal;
      } else {
        return 'Logo Cintelink';
      }
    },
    channelName(){
      if (this.canal.canal) {
        return this.canal.canal[0].toUpperCase();
      } else return '';
    }
  },
  watch: { 
    permissions: function() {
      this.renderMenu();
    },
    isMobile: function() {
      this.renderMenu();
    }
  },

  methods: {
    menuHasSubmenu(m) {
      for (const prop in m) {
        let obj = this._.get(m,prop);
        if (obj.render == true) {
           return true;
        }
      }
      return false;
    },
    separator(height) {
      return {
        render (h) {
          return h('hr', {
            style: {
              borderColor: 'rgba(0, 0, 0, 0)',
              color: 'transparent',
              marginBottom: height
            }
          })
        }
      }
    },
    renderMenu() {
      let height = (!this.isAdmin || (this.isAdmin && !this.hasManuals)) ? this.window.height - 300 : this.window.height - 350; 

      height = this.isMobile ? Math.floor(height/2) + "px" : height + 'px';
      let separator = this.separator(height);
      this.menu = [];
      this.menu.push(this.listaMenu);

      let item = {
        component: separator,
        hidden: true,
        hiddenOnCollapse: false
      }
      if (this.hasManuals) {
        this.menu.push(this.remitoMenu);
        if (this.isAdmin) {
          this.menu.push(this.configMenu);
        }
        this.menu.push(item);
        if(this.canal.canal !== 'ypf'){
          this.menu.push(this.infoMenu);
        }
      } else {
        if (this.isAdmin) {
          this.menu.splice(this.configMenu);
        }
        this.menu.push(item);
        if(this.canal.canal !== 'ypf'){
          this.menu.push(this.infoMenu);
        }
      }

      if (this.isMobile) {
        this.menu.push(this.inicioMenu);
        this.menu.push(this.logOutMenu);
      } else {
        this._.pull(this.inicioMenu, this.logOutMenu);
      }
    },
    onToggleCollapse (collapsed){
        this.isCollapsed = collapsed;
        this.collapsed = collapsed;
    },
    onItemClick (event,item){ //existe parametro node => onItemClick (event,item,node)
      if (item.icon.element.includes('info')){
        this.$bvModal.show('about-us')
      }
      if (this.window.width < 768){
        this.collapsed = !this.collapsed;
        this.isCollapsed = !this.collapsed;
      }
    },
    toggle() {
      this.collapsed = !this.collapsed;
    },
    hasLoaded() {
      this.loaded = true;
    },
    failLoad() {
      if (this.canal.canal != '') {
        this.failLoadImg = true;
        this.loaded = true;
      }
    },
  }
}

</script>