import Vue from 'vue';
import VueRouter from 'vue-router';
import SupplyView from '../views/SupplyView.vue';
import AdministracionView from '../views/AdministracionView.vue';
import NotFound from '../views/NotFound.vue';
import Sidebar from '../components/Sidebar.vue';
import Footer from '../components/Footer.vue';
import ConfigAdmin from '../views/ConfigAdmin.vue';
import MenuView from "../views/MenuView.vue";
import axios from 'axios';
axios.defaults.withCredentials = true;
const urlBack = process.env.VUE_APP_ENDPOINT_API;
const SUPPLY_READ = function (e) { return (e.objeto === 'SUPPLY' || e.objeto === 'SUPPLY_CANAL') && e.tipo === 'READ' }
const MANUAL_SUPPLY_READ = function (e) { return (e.objeto === 'MANUAL_SUPPLY' || e.objeto === 'MANUAL_SUPPLY_CANAL') && e.tipo === 'READ' }
const SYSTEM_READ = function (e) { return e.objeto === 'SYSTEM' && e.tipo === 'READ' }
Vue.use(VueRouter);

async function getToken () {
    const config = {
        headers: {
            Authorization: ''
        }
    }
    try {
        const response = await axios.get(urlBack + "/permissions", config);
        let isAdmin = response.data.data.permissions.find(e => SYSTEM_READ(e)) !== undefined ? true : false;
        let prop = {
            token: '',
            permissions: response.data.data.permissions,
            canal: response.data.data.canal,
            isAdmin,
            admin_no_modo_empresa: response.data.data.admin_no_modo_empresa
        };
        localStorage.setItem('canal', prop.canal);
        return prop;
    } catch (error) {
        console.log(error);
        let defeated = null;
        if (error.response) {
            if (error.response.status == 401) {
                defeated = true;
            }
        }
        let prop = {
            token: '',
            permissions: [],
            canal: {
                canal: 'cintelink',
                id_canal: '4',
                default_name: 'Cintelink'
            },
            isAdmin: false,
            defeated: defeated !== null ? defeated : undefined,
            admin_no_modo_empresa: false
        };
        prop.canal = localStorage.getItem('canal') === 'ypf' ? {canal:'ypf', id_canal: '5', default_name: 'Cintelink'} : prop.canal;
        return prop;
    }
    
}

function passProps(to) {
    return to.meta.props;
}

const routes = [
    {
        path: "/",
        name: 'home',
        components: {
            default: SupplyView,
            sidebar: Sidebar,
            footer: Footer,
            title: MenuView
        },
        props: {
            default: passProps,
            sidebar: passProps,
            footer:passProps,
            title: passProps
        }
    },
    {
        path: "/administrar",
        name: 'admin',
        components: {
            default: AdministracionView,
            sidebar: Sidebar,
            footer: Footer,
            title: MenuView
        },
        props: {
            default: passProps,
            sidebar: passProps,
            footer:passProps,
            title: passProps
        }
    },
    {
        path: "/administrar/:id_manual_supply",
        name: 'admin-modal',
        components: {
            default: AdministracionView,
            sidebar: Sidebar,
            footer: Footer,
            title: MenuView
        },
        props: {
            default: passProps,
            sidebar: passProps,
            footer:passProps,
            title: passProps
        }
    },
    {
        path: "/config",
        name: 'tanks',
        components: {
            default: ConfigAdmin,
            sidebar: Sidebar,
            footer: Footer,
            title: MenuView
        },
        props: {
            default: passProps,
            sidebar: passProps,
            footer:passProps,
            title: passProps
        }
    },
    {
        path: "/session-timeout",
        name: '401',
        components: {
            default: NotFound,
            sidebar: Sidebar,
            footer: Footer,
            title: MenuView
        },
        props: {
            default: passProps,
            sidebar: passProps,
            footer:passProps,
            title: passProps
        }
    },
    {
        path: "/sin-modo-empresa",
        name: '403',
        components: {
            default: NotFound,
            sidebar: Sidebar,
            footer: Footer,
            title: MenuView      
        },
        props: {
            default: passProps,
            sidebar: passProps,
            footer:passProps,
            title: passProps
        }
    },
    {
        path: "/page-not-found",
        name: '404',
        alias: '*',
        components: {
            default: NotFound,
            sidebar: Sidebar,
            footer: Footer,
            title: MenuView      
        },
        props: {
            default: passProps,
            sidebar: passProps,
            footer:passProps,
            title: passProps
        }
    }
];

const router = new VueRouter({
    routes,
    mode: 'history',
    scrollBehavior (to, from, savedPosition) {
        if (to.hash) {
          return {
            selector: to.hash,
            behavior: 'smooth',
          };
        } 
        if (savedPosition) {
            return savedPosition;
        }
        return {x:0,y:0};
    }
});

var cacheAuth = null;

router.beforeEach(async (to, from, next)=>{
    let auth = cacheAuth ? cacheAuth : await getToken();
    
    //cache manaos
    cacheAuth = auth;
    //fin cache manaos
    
    to.meta.props = auth;
    if (to.name === '404' || to.name === '401' || to.name === '403') {
        to.meta.props.defeated = to.name != '401' ? false : to.meta.props.defeated;
        next();
        return;
    }
    if (auth.admin_no_modo_empresa) {
        next({name:'403', params: to.params });
        return;
    }

    if (auth.permissions.length != 0) {
        let hasManual,isAdmin,hasSupply;
        switch(to.name) {
            case 'admin':
            case 'admin-modal':
                hasManual = auth.permissions.find(e=> MANUAL_SUPPLY_READ(e));
                to.meta.props.title = "Administración de Remitos";
                to.meta.props.cssClass = ["wrapper", "p-1", "pl-4"];
                if (hasManual) {
                    next(); 
                    return;
                }
                
            break;
            case 'tanks':
                to.meta.props.title = "Configuración de Tanques";
                to.meta.props.cssClass = [];
                isAdmin = auth.permissions.find(e=> SYSTEM_READ(e));
                if (isAdmin) {
                    next(); 
                    return;
                }
                
            break;   
            default: 
                to.meta.props.title = "Reporte de Abastecimientos";
                to.meta.props.cssClass = [];
                hasSupply = auth.permissions.find(e=> SUPPLY_READ(e));
                if (hasSupply) {
                    next(); 
                    return;
                }
            break;
        }
        if (to.meta.props.defeated) {
            next({name:'401', params: to.params });
        } else {
            next({name:'404', params: to.params });
        }
    } else {
        if (to.meta.props.defeated) {
            next({name:'401', params: to.params });
        } else {
            next({name:'404', params: to.params });
        }
    }
})

export default router;