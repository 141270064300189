<template>
<div>
    <div v-if="canal.canal != 'ypf'" :class="canal.canal!=='ypf'? 'footer mt-4' : ''">
        <b-row v-if="!isSmall">
            <b-col style="text-align:center;" class="pt-1">
                Cintelink Argentina | Fuel Business Intelligence SA | Todos los Derechos Reservados |
                <a :href="core_uri + '/terminos.mod.php' " target=_blank>Terminos y Condiciones</a>
            </b-col>
        </b-row>
        <b-row v-else>
            <b-col cols="12" style="text-align:center;">Cintelink Argentina </b-col>
            <b-col cols="12" style="text-align:center;"> Fuel Business Intelligence SA</b-col> 
            <b-col cols="12" style="text-align:center;">Todos los Derechos Reservados</b-col>
            <b-col cols="12" style="text-align:center;">
                <a :href="core_uri + '/terminos.mod.php' " target=_blank>Terminos y Condiciones</a>
            </b-col>
        </b-row>
    </div>
</div>
</template>
<script>
export default {
    name: 'Footer',
    props: {
        core_uri:String,
        window: Object,
        canal: {
            canal: String,
            id_canal: String,
            default_name: String
        }
    },
    computed: {
        isSmall() {
            return this.window.width <= 768 ? true : false;
        },
    }
}
</script>