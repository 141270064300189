<template>
    <b-container :fluid="isMobile ? false : true" :class="isMobile ? '' : ''" class="m-3">

        <b-row class="m-4">
            <b-col>
                <TankList v-if="isAdmin"
                    :token="token"
                    :endpoint_api="endpoint_api"
                    :isMobile="isMobile"
                    :permissions="permissions"
                    />
            </b-col>
        </b-row>
        
        <b-row :class="isMobile ? '' : 'd-flex w-100'" align-self="end">
            <b-col :class="'pl-3'" align-self="end">
            </b-col>
        </b-row>

    </b-container>
</template>
<script>
import TankList from '../components/TankList';
export default {
    components: {
        TankList,
    },
    title: 'Tanques | Configuración |',
    props: {
        token: String,
        endpoint_api: String,
        isMobile: Boolean,
        permissions: Array,
        isAdmin: Boolean,
        canal: Object
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.setTitle(vm.canal.canal);      
        });
    },
    watch: {
        'canal.canal' (val) {
            this.setTitle(val);
        }
    },
}
</script>