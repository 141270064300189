<template>
<b-container :fluid="isMobile ? false : true" :class="classes">
    <b-row :class="isMobile ? 'd-flex w-100':'d-flex w-100 mt-4 mb-4 pl-4'" align-self="end" >
        <b-col :class="isMobile ? '' :'pl-3'" align-self="start">
            <h1 style="font-weight:bold">{{title}}</h1>
        </b-col>
        <b-col class="pr-0">
            <MenuUsuario v-if="!isMobile" :core_uri="core_uri"/>
        </b-col>
    </b-row>
</b-container>
</template>
<script>
import MenuUsuario from "../components/MenuUsuario.vue";
export default {
    components: {
        MenuUsuario
    },
    props: {
        core_uri:String,
        title: String,
        isMobile: Boolean,
        cssClass: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        classes() {
            return this.cssClass.join(" ");
        }
    }
}
</script>