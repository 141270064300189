<template>
    <b-container :fluid="isMobile ? false : true" :class="isMobile ? '' : ''">

        <b-row :class="isMobile ? '' : 'd-flex w-100 pl-3'" align-self="end" >
            <b-col :class="isMobile ? '':'pl-3'" align-self="start">
                <SupplyList 
                    :permissions="permissions"
                    :token="token"
                    :endpoint_api="endpoint_api"/>
            </b-col>
        </b-row>
        
        <b-row :class="isMobile ? '' : 'd-flex w-100'" align-self="end">
            <b-col :class="'pl-3'" align-self="end">
            </b-col>
        </b-row>

    </b-container>
</template>
<script>
import SupplyList from '../components/SupplyList';

export default {
    title: 'Abastecimientos | ',
    components: {
        SupplyList,
    },
    props: {
        token: String,
        endpoint_api: String,
        isMobile: Boolean,
        permissions: Array,
        canal: Object,
        core_uri: String
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.setTitle(vm.canal.canal);        
        });
    },
    watch: {
        'canal.canal' (val) {
            this.setTitle(val);
        }
    },
}
</script>