import Vue from 'vue';
import App from './App.vue';
import lodash from 'lodash';
import VueLodash from 'vue-lodash';
import round from 'vue-round-filter';
import excel from "vue-json-excel";
import router from './routers/router';
import { BootstrapVue, IconsPlugin, ButtonGroupPlugin } from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import VueSidebarMenu from 'vue-sidebar-menu';
import vSelect from "vue-select";

import './helpers/validators';

import './assets/css/custom.scss';
import './assets/css/inspinia.css';
import './assets/css/styles.css';

import 'vue-sidebar-menu/dist/vue-sidebar-menu.css';
import './assets/css/styles.scss';
import './assets/css/sidebar.scss';
import './assets/css/cintefont.scss';
import "vue-select/dist/vue-select.css";
import titleMixin from './mixins/titleMixin';

Vue.use(VueSidebarMenu);

Vue.mixin(titleMixin);
Vue.component("v-select", vSelect);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

Vue.config.productionTip = false

Vue.filter('round', round)

Vue.use(VueLodash, {lodash});
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(ButtonGroupPlugin)
Vue.component("downloadExcel", excel);

new Vue({
  router,
  filters: {
    round,
  },
  render: h => h(App)
}).$mount('#app');
