const dotenv = require('dotenv');
const path = require('path');
dotenv.config({path: path.resolve(__dirname,'../.env')});
//===============================
// Parámetros
//===============================
exports.VUE_APP_CORE_URI = process.env.VUE_APP_CORE_URI ? process.env.VUE_APP_CORE_URI : 'https://cintelink.com.ar/';
exports.NODE_ENV = process.env.NODE_ENV ? process.env.NODE_ENV : 'production';
exports.VUE_APP_COLORED_CHANNELS = process.env.VUE_APP_COLORED_CHANNELS ? process.env.VUE_APP_COLORED_CHANNELS.split(',') : [ 'ypf', 'nectar', 'lacolonia', 'urbaser', 'matres'];
exports.VUE_APP_MAINTENANCE_MODE = process.env.VUE_APP_MAINTENANCE_MODE ? process.env.VUE_APP_MAINTENANCE_MODE : 0;
exports.VUE_APP_MAINTENANCE_MSG = process.env.VUE_APP_MAINTENANCE_MSG ? process.env.VUE_APP_MAINTENANCE_MSG : '';
