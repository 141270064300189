function getTitle (vm) {
  const { title } = vm.$options;
  if (title) {
    return typeof title === 'function'
      ? title.call(vm)
      : title
  }
}
function setTitle (val='cintelink') {
  let title = getTitle(this).split('|');
  if (val === 'ypf') {
    title[title.length - 1] = 'YPF';
    setIcon(val);
  } else {
    title[title.length - 1] = 'Cintelink';
    setIcon();
  }
  title = title.toString().replaceAll(',', '| ');
  document.title = title;
  return;
}
function setIcon(val='cintelink') { 
  const favicon = document.getElementById("favicon");
  favicon.href = val === 'ypf' ? "/ypf.ico" : '/favicon.ico';
  return;
}
export default {
  created () {
    const title = getTitle(this);
    if (title) {
      document.title = title;
    }
  },
  methods: {
    setTitle,
    getTitle
  }
}